import React, { useMemo } from 'react';
import { Paper, Typography, Box, Grid } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import GradeProgressCircle from './GradeProgressCircle';
import { localizeDate } from '../../utils/dateHelpers';

const StudentDetails = ({ student }) => {
  const grade = student.grade % 1 > 0.85 ? Math.ceil(student.grade) : (student.grade % 1 < 0.15 ? Math.floor(student.grade) : student.grade);
  const maximumGrade = student.maximumGrade % 1 > 0.85 ? Math.ceil(student.maximumGrade) : (student.maximumGrade % 1 < 0.15 ? Math.floor(student.maximumGrade) : student.maximumGrade);

  const gradePercentage = (grade / (maximumGrade ?? 100)) * 100;
  const date = useMemo(() => localizeDate(student.date), [])
  return (
    <Paper elevation={1} style={{ padding: '15px', marginBottom: '20px' }}>
      <Grid container spacing={2} alignItems="center">
        {/* Student Details */}
        <Grid item xs={12} sm={8} md={8}>
          <Grid container direction="column" spacing={2}>
            {/* Student Name */}
            <Grid item display="flex" alignItems="center">
              <AccountCircleOutlinedIcon color="primary" sx={{ margin: '3px' }} />
              <Typography variant="h6">
                {student.student_name || student.full_name || student.fullname || ''}
              </Typography>
            </Grid>

            {/* Grade (textual representation) */}
            <Grid item display="flex" alignItems="center">
              <TaskAltIcon color="primary" sx={{ margin: '3px' }} />
              <Typography variant="h6">
                {parseFloat(grade)}/{parseFloat(maximumGrade ?? 100)}
              </Typography>
            </Grid>

            {/* Date */}
            <Grid item display="flex" alignItems="center">
              <AccessTimeIcon color="primary" sx={{ margin: '3px' }} />
              <Typography variant="h6">
                {date}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Circular Progress Bar */}
        <Grid item xs={12} sm={4} md={4} display="block" justifyContent="center">
          <GradeProgressCircle gradePercentage={gradePercentage} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StudentDetails;
