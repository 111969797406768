import { useState } from 'react';
import { Button } from '@mui/material';
import ShareDialog from './ShareDialog';
import { useLanguage } from '../../contexts/languageContext';
import LMSShareDialog from './LMSShareDialog';

function ShareButton({
    languageData: previewLanguageData,
    examId,
    variant = 'contained',
    icon = null,
    color = 'primary',
    fromLMS = false, // Flag to check if the user is from LMS
}) {
    const { isRTL } = useLanguage();
    const [openShareDialog, setOpenShareDialog] = useState(false);
    const [openLMSDialog, setOpenLMSDialog] = useState(false);
    const [lmsData, setLmsData] = useState(null); // Store fetched LMS data

    const handleShareClick = () => {
        if (fromLMS) {
            setOpenLMSDialog(true);
        } else {
            setOpenShareDialog(true);
        }
    };

    const handleCloseShareDialog = () => {
        setOpenShareDialog(false);
    };
    const handleCloseLMSDialog = () => {
        setOpenLMSDialog(false);
    };
    const handleLMSConfirm = () => {
        setOpenLMSDialog(false);
    }

    return (
        <>
            <Button
                variant={variant}
                color={color}
                onClick={handleShareClick}
                endIcon={icon}
            >
                {previewLanguageData?.share_exam ?? "Share"}
            </Button>
            {!fromLMS && (
                <ShareDialog
                    isRTL={isRTL}
                    languageData={previewLanguageData?.share_dialog}
                    open={openShareDialog}
                    onClose={handleCloseShareDialog}
                    examId={examId}
                />
            )}
            {fromLMS && (
                <LMSShareDialog
                    isRTL={isRTL}
                    languageData={previewLanguageData?.lms_dialog}
                    open={openLMSDialog}
                    onClose={handleCloseLMSDialog}
                    lmsData={lmsData}
                    setLmsData={setLmsData} // Pass the setter to cache LMS data
                    onConfirm={handleLMSConfirm}
                    examId={examId}
                />
            )}
        </>
    );
}

export default ShareButton;
