import { colors } from "./customTheme";

export const getSeverityStyles = (severity) => {
    switch (severity) {
        case "error":
            return {
                borderColor: "#d32f2f",
                backgroundColor: "#f9ebeb",
            };
        case "warning":
            return {
                borderColor: "#ff9800",
                backgroundColor: "#fff3e0",
            };
        case "info":
            return {
                borderColor: "#1976d2",
                backgroundColor: "#e3f2fd",
            };
        case "success":
            return {
                borderColor: "#388e3c",
                backgroundColor: "#e8f5e9",
            };
        default:
            return {
                borderColor: "black",
                backgroundColor: colors.bg,
            };
    }
};

export const getSeverityForGrade = (grade, maxGrade = 100) => {
    grade = parseFloat(grade);
    maxGrade = parseFloat(maxGrade);
    const percentage = (grade / maxGrade) * 100;
    if (percentage >= 80) {
        return "success";
    } else if (percentage < 50) {
        return "warning";
    } else {
        return "info";
    }
};