import React, { useMemo, useState } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import UndoIcon from '@mui/icons-material/Undo'; // Import an alternate icon
import { useLanguage } from '../../contexts/languageContext';
import { isMobile } from '../../utils';
import { useUser } from '../../hooks/useUser';
import { sendAllReviewsToLms } from '../../requests/quiz';
import LMSShareDialog from '../../components/Share/LMSShareDialog';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';

const SubmitAndShareButton = ({ allReviewsSubmitted = true, handleSaveSettings, examJson }) => {
    const { languageData, isRTL } = useLanguage();
    const { show_results } = examJson?.settings || {};
    const { isFromLMS } = useUser();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [lmsData, setLmsData] = useState(null);
    const { openSnackBar } = useSnackBar();

    const handleClick = () => {
        if (isFromLMS) {
            setDialogOpen(true);
            return
        }

        handleSaveSettings({
            ...examJson.settings,
            show_results: !show_results,
        });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogConfirm = async () => {
        let examId = examJson._id;
        return sendAllReviewsToLms(examId).then(() => {
            openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field);
            handleSaveSettings({
                ...examJson.settings,
                show_results: true,
            });
        }).catch((err) => {
            console.error('Failed to send grades to LMS:', err);
            openSnackBar(SnackbarTypes.ERROR_CONTACT.field);
        }).finally(() => {
            setDialogOpen(false);
        });
    };

    const showUndo = useMemo(() => {
        return show_results && !isFromLMS;
    }, [show_results, isFromLMS]);

    return (
        <>
            <Tooltip
                title={!allReviewsSubmitted
                    ? (languageData?.review?.share_tooltip ?? 'Submit reviews to all students to enable sharing')
                    : ''
                }
                placement="top"
                arrow
            >
                <Box
                >
                    <Button
                        color={showUndo ? 'error' : 'primary'}
                        onClick={handleClick}
                        disabled={!allReviewsSubmitted}
                        variant="contained"
                        endIcon={showUndo ? <UndoIcon /> : <SendIcon />}
                    >
                        {showUndo
                            ? (languageData?.review?.undo_share_label ?? 'Undo Sharing')
                            : (languageData?.review?.share_label ?? 'Submit & Share')}
                    </Button>
                </Box>
            </Tooltip>
            <LMSShareDialog
                title={languageData?.lms_dialog?.title}
                description={languageData?.lms_dialog?.description}
                label={languageData?.lms_dialog?.label}
                isRTL={isRTL}
                languageData={languageData?.preview}
                open={dialogOpen}
                onClose={handleDialogClose}
                lmsData={lmsData}
                setLmsData={setLmsData}
                onConfirm={handleDialogConfirm}
                examId={examJson?._id}
                syncGrades
            />
        </>
    );
};

export default SubmitAndShareButton;
