import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Container, Box, Typography, Button } from '@mui/material';
import Teachers from './Teachers';
import DashboardChart, { chartTypes } from './DashboardChart';
import { useManagerDashboard } from '../../contexts/ManagerDashboardContext';
import Header from '../../components/Header';
import { useUser } from '../../hooks/useUser';
import OrgSummary from './OrgSummary';
import html2pdf from 'html2pdf.js';
import Institutions from './Institutions';

const ManagerDashboard = () => {
    const { isAdmin } = useUser();
    const { org_id } = useParams();
    const dashboardRef = useRef(null);  // Reference to the dashboard content

    useEffect(() => {
        isAdmin();
    }, []);

    const { hasAccess, fetchExamTaken, fetchResponsesCount, fetchTokensCount, fetchOrg, managerDashboardData, fetchExamsSources } = useManagerDashboard();

    useEffect(() => {
        if (hasAccess && org_id && !managerDashboardData?.org) {
            fetchOrg(org_id);
        }
    }, [org_id, hasAccess]);

    if (!hasAccess) {
        return null;
    }

    const downloadPDF = () => {
        const element = dashboardRef.current;

        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5], // Reduce margins for better fit
            filename: 'manager_dashboard.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 1.5, // Try adjusting scale to make content fit
                useCORS: true,
                logging: true,
                width: 1000, // Adjust the width to scale down content, this can be fine-tuned
                height: 1970 // Adjust the height similarly
            },
            jsPDF: {
                unit: 'mm', // Change to mm for more precise control over layout
                format: 'a4', // A4 size
                orientation: 'portrait', // Portrait orientation
                compress: true,
                autoPaging: true // Allow auto pagination if content exceeds one page
            },
        };
        const teachersList = document.getElementById('teachersList');
        if (teachersList) {
            teachersList.style.display = 'none'; // Hide the teachers list before saving PDF
        }
        // Delay to ensure charts are rendered fully
        setTimeout(() => {
            html2pdf()
                .from(element)
                .set(opt)
                .save()
                .finally(() => {
                    // Restore the visibility of the teachers list after PDF is saved
                    if (teachersList) {
                        teachersList.style.display = 'grid'; // Restore visibility
                    }
                });
        }, 500);
    };
    return (
        <>
            <Header />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 10 }} ref={dashboardRef}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                            {managerDashboardData?.org?.logo && <img src={`data:image/png;base64, ${managerDashboardData?.org?.logo}`} height="60px" alt="Organization Logo" />}
                            <Typography variant="h4" component="h1" gutterBottom>{managerDashboardData?.org?.name}</Typography>
                        </Box>

                        {/* Download PDF Button */}
                        <Button variant="contained" color="error" onClick={downloadPDF}>
                            Download as PDF
                        </Button>
                    </Grid>

                    {/* Summary Cards */}
                    <OrgSummary orgId={org_id} />

                    {/* Teachers Table */}
                    <Grid item xs={12} md={12} lg={12} id="teachersList">
                        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
                            <Teachers orgId={org_id} />
                        </Box>
                    </Grid>

                    {/* Institutions */}
                    <Grid item xs={12} md={12} lg={12} id="teachersList">
                        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
                            <Institutions orgId={org_id} />
                        </Box>
                    </Grid>

                    {/* Tokens Graph */}
                    <Grid item xs={12} md={6} lg={6}>
                        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
                            <DashboardChart
                                orgId={org_id}
                                title='Token Usage'
                                fetchData={fetchTokensCount}
                                data={managerDashboardData?.tokensCount}
                                dataKey='tokens'
                                chartColor='#8884d8'
                                chartType={chartTypes.areaChart}
                            />
                        </Box>
                    </Grid>

                    {/* Students Component */}
                    <Grid item xs={12} md={6} lg={6}>
                        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
                            <DashboardChart
                                orgId={org_id}
                                title='Student Responses'
                                fetchData={fetchResponsesCount}
                                data={managerDashboardData?.responsesCount}
                                dataKey='responses'
                                chartColor='#ff7300'
                                chartType={chartTypes.barChart}
                            />
                        </Box>
                    </Grid>

                    {/* Exams Component */}
                    <Grid item xs={12} md={6} lg={6}>
                        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
                            <DashboardChart
                                orgId={org_id}
                                title='Exams Created'
                                fetchData={fetchExamTaken}
                                data={managerDashboardData?.examsTaken}
                                dataKey='exams'
                                chartColor='#82ca9d'
                                chartType={chartTypes.barChart}
                            />
                        </Box>
                    </Grid>

                    {/* Sources Component */}
                    <Grid item xs={12} md={6} lg={6}>
                        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
                            <DashboardChart
                                orgId={org_id}
                                title='All Time Exams Sources'
                                fetchData={fetchExamsSources}
                                data={managerDashboardData?.sources}
                                dataKey='count'
                                nameKey='_id'
                                chartColor='#82ca9d'
                                chartType={chartTypes.pieChart}
                                allTime
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default ManagerDashboard;