import React from 'react'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { HttpStatusCode } from 'axios'
import { handleSelectedtierIfExist } from '../utils'
import { GoogleLogin } from '@react-oauth/google'
import { useUser } from '../hooks/useUser'
import api from '../requests/api'
import useHistory from '../hooks/useHistory'
import { handleRedirectAfterAuth } from '../utils/auth'
import { Box } from '@mui/material'
import { allowFocusStyle } from '../utils/customTheme'

const ContinueWithGoogleButton = (props) => {
  const navigate = useNavigate()
  const { login } = useUser()
  const historyContext = useHistory()

  /**
   * credential is the access token
   * @param {{ credential: string, clientId: string, select_by: string }} response 
   */
  const handleOnSuccess = async (response) => {
    let userObject = jwt_decode(response.credential)
    const { email, name } = userObject
    const payload = {
      user: {
        email,
        fullname: name,
      },
      googleIdToken: response.credential
    }

    if (props.token) {
      payload.token = props.token
    }

    try {
      const res = await api.post(`/auth/google`, payload);
      if (res.status === HttpStatusCode.Ok) {
        handleSelectedtierIfExist()
        login(res.data);
        historyContext.resetHistory()
        handleRedirectAfterAuth(navigate, res?.data)
      }
    } catch (error) {
      console.error('Error making POST request to /auth:', error);
    }
  }

  return (
    <Box sx={{ ...allowFocusStyle, width: '100%' }}>
      <GoogleLogin
        onSuccess={handleOnSuccess}
        onError={() => {
          console.error('Login Failed')
        }}
        useOneTap
        size='large'
      />
    </Box>
  )

}

export default ContinueWithGoogleButton
