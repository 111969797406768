import { useEffect } from "react";
import { useManagerDashboard } from "../../contexts/ManagerDashboardContext";
import TimeSavedCard from "../Lobby/TimeSavedCard";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";

function OrgSummary({ orgId }) {
    const { fetchExamResponseSummary, managerDashboardData } = useManagerDashboard();
    const examResponseSummary = managerDashboardData?.examResponseSummary || [];
    useEffect(() => {
        if (orgId) {
            fetchExamResponseSummary(orgId);
        }
    }, [orgId]);

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    width: '100%',
                    gap: 2,
                }}
            >
                {/* Card for Total Exams */}
                <Card sx={{ flex: 1, p: 2, bgcolor: 'primary.main', borderRadius: 1 }}>
                    <CardContent>
                        <Typography variant="h6" component="div" color="white">
                            Total Exams
                        </Typography>
                        <Typography variant="h4" component="div" color="white" sx={{ fontWeight: 'bold' }}>
                            {examResponseSummary[0]?.total_exams}
                        </Typography>
                    </CardContent>
                </Card>

                {/* Card for Total Responses */}
                <Card sx={{ flex: 1, p: 2, bgcolor: 'secondary.main', borderRadius: 1 }}>
                    <CardContent>
                        <Typography variant="h6" component="div" color="white">
                            Total Responses
                        </Typography>
                        <Typography variant="h4" component="div" color="white" sx={{ fontWeight: 'bold' }}>
                            {examResponseSummary[0]?.total_responses}
                        </Typography>
                    </CardContent>
                </Card>
            </Box>

            {/* Time Saved Card */}
            <Box sx={{ mt: 2 }}>
                <TimeSavedCard
                    examsCreated={examResponseSummary[0]?.total_exams}
                    examsEvaluated={examResponseSummary[0]?.total_responses}
                />
            </Box>
        </Grid>
    )
}

export default OrgSummary;