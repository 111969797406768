import DownloadIcon from '@mui/icons-material/Download';
import { Button } from "@mui/material";
import { useLanguage } from '../../contexts/languageContext';

export const DownloadCSVButton = ({ list, filename, headersMap = {}, buttonStyle = {}, iconOnly = false, buttonLabel = '' }) => {
    const { isRTL, languageData } = useLanguage();
    const handleDownloadCSV = () => {
        const csvContent = convertArrayToCsv(list, headersMap);
        // Create a Blob and initiate the download
        const blob = new Blob(['\uFEFF', csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename ? filename + '.csv' : `whitelist.csv`;
        link.click();
    };

    const label = ` ${buttonLabel || (languageData?.download_label || 'Download')}`;
    return (
        <Button
            variant="contained"
            color="success"
            sx={{ direction: isRTL ? 'ltr' : 'rtl', ...buttonStyle }}
            onClick={handleDownloadCSV}
            disabled={!list || !list?.length}
        >
            <DownloadIcon />{!iconOnly && label}
        </Button>
    )
}

function convertArrayToCsv(jsonData, headersMap = {}) {
    if (jsonData.length === 0) return '';

    // Automatically format headers if not provided
    const formatHeader = (key) => key
        .replace(/_/g, ' ')             // Replace underscores with spaces
        .replace(/\b\w/g, (l) => l.toUpperCase()); // Capitalize each word

    const headers = Object.keys(jsonData[0]).map(key =>
        headersMap[key] || formatHeader(key)
    );

    const csvContentArray = [];
    csvContentArray.push(headers.join(',')); // Add formatted headers

    // Add rows
    jsonData.forEach(row => {
        const values = Object.keys(row).map(key => row[key] ?? ''); // Handle missing values
        csvContentArray.push(values.join(','));
    });

    return csvContentArray.join('\n');
}