export const extractYouTubeVideoId = (url) => {
    if (url.trim() === '') {
        return null;
    }
    const videoIdRegex = /[?&]v=([^?&]+)|youtu\.be\/([^?&]+)/;
    const match = url.match(videoIdRegex);
    return match ? match[1] || match[2] : null;
};

export const displayParameters = (questions = [], difficulty = null, languageData) => {

    const questionsAmount = questions.reduce((acc, question) => {
        if (question.type)
            acc[question.type] += 1
        return acc
    }, { multiple_choice: 0, open_questions: 0, fill_in_the_blank: 0, graph_questions: 0, nested_questions: 0 });
    const { multiple_choice, open_questions, fill_in_the_blank, graph_questions, nested_questions } = questionsAmount;
    const fields = [];

    if (multiple_choice && multiple_choice > 0) {
        fields.push(languageData?.questionsTypesOptions?.[0] || 'Multiple Choice: ' + multiple_choice);
    }
    if (open_questions && open_questions > 0) {
        fields.push(languageData?.questionsTypesOptions?.[1] || 'Open Questions: ' + open_questions);
    }
    if (fill_in_the_blank && fill_in_the_blank > 0) {
        fields.push(languageData?.questionsTypesOptions?.[2] || 'Fill in the Blank: ' + fill_in_the_blank);
    }
    if (graph_questions && graph_questions > 0) {
        fields.push(languageData?.questionsTypesOptions?.[4] || 'Graph Questions: ' + graph_questions);
    }
    if (nested_questions && nested_questions > 0) {
        fields.push(languageData?.questionsTypesOptions?.[3] || 'Nested Questions: ' + nested_questions);
    }

    if (difficulty && difficulty.trim() !== '') {
        fields.push(languageData?.difficulty_label || 'Difficulty: ' + difficulty);
    }

    return fields.join(', ');
};

export const displayPersonalizedData = (personalizedData, languageData) => {
    const { focus, exampleQuestion } = personalizedData;
    const fields = [];

    if (focus) {
        fields.push((languageData?.focus_style_dialog?.title + ": " || 'Focus: ') + focus);
    } else {
        fields.push(languageData?.focus_style_dialog?.focus_recommendation || 'We recommend adding focus for better results');
    }

    if (exampleQuestion) {
        fields.push((languageData?.focus_style_dialog?.example_question || 'Example Question: ') + exampleQuestion);
    }

    return fields.join(', ');
};
