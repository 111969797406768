import React from 'react';
import { Tabs, Tab, Box, Grid } from '@mui/material';

const CustomTabs = ({ value, onChange, children }) => {
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} >
                <Tabs
                    value={value}
                    onChange={onChange}
                    aria-label="review tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ mt: 2 }}
                >
                    {children.map((child, index) => (
                        <Tab
                            key={index}
                            label={child?.props?.label}
                            value={index.toString()}
                            sx={{ fontSize: '1rem' }}
                        />
                    ))}
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                {children.map((child, index) => (
                    value === index.toString() && (
                        <Box key={index}>
                            {child.props.children}
                        </Box>
                    )
                ))}
            </Grid>
        </Grid>
    );
};

export default CustomTabs;
