import { homepage, onboarding } from "../components/menu/MenuLinks"
import { getOnboardingData } from "../requests/onboarding"

/**
 * Handles the redirect after authentication based on the user's role and onboarding data.
 * @param {function} navigate - The navigate function from the React Router.
 * @param {object} user - The user object containing user information.
 * @returns {Promise<void>} - A promise that resolves when the redirect is handled.
 */
export async function handleRedirectAfterAuth(navigate, user = null) {
    try {
        const onboardingData = await getOnboardingData()
        if (!onboardingData || !onboardingData.role) {
            handleRedirectUrlAfterSignIn(navigate, onboarding)
        } else {
            handleRedirectUrlAfterSignIn(navigate, homepage)
        }
    } catch (e) {
        handleRedirectUrlAfterSignIn(navigate, onboarding)
    }
}

export function handleRedirectUrlAfterSignIn(navigate, urlIfNoRedirect=homepage) {
  const redirectUrl = sessionStorage.getItem('redirectUrl');
  if (redirectUrl) {
    sessionStorage.removeItem('redirectUrl');
    navigate(redirectUrl)
  } else {
    navigate(urlIfNoRedirect)
  }
}