import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { useManagerDashboard } from '../../contexts/ManagerDashboardContext';

const Institutions = () => {
    const { managerDashboardData } = useManagerDashboard();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (managerDashboardData?.users) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [managerDashboardData?.users]);


    // Memoize columns to ensure stability
    const columns = useMemo(() => [
        { field: 'institute', headerName: 'Institute Name', flex: 2 },
        { field: 'num_users', headerName: 'Users', flex: 1 },
        { field: 'total_exams', headerName: 'Exams', flex: 1 },
        { field: 'total_responses', headerName: 'Responses', flex: 1 },
        { field: 'champion', headerName: 'Champion', flex: 2 },
    ], []);

    // Memoize grouped data
    const groupedData = useMemo(() => {
        if (!managerDashboardData?.users) return [];
        return managerDashboardData.users.reduce((acc, user) => {
            const instituteName = user.org_details?.institute_name || 'Unknown';
            if (!acc[instituteName]) {
                acc[instituteName] = {
                    institute: instituteName,
                    total_exams: 0,
                    total_responses: 0,
                    num_users: 0,
                    champion: { name: '', total: 0 },
                };
            }
            const userTotal = (user.total_exams || 0) + (user.total_responses || 0);
            acc[instituteName].total_exams += user.total_exams || 0;
            acc[instituteName].total_responses += user.total_responses || 0;
            acc[instituteName].num_users += 1;
            if (userTotal > acc[instituteName].champion.total) {
                acc[instituteName].champion = {
                    name: user.fullname || '---',
                    total: userTotal,
                };
            }
            return acc;
        }, {});
    }, [managerDashboardData?.users]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="body1" color="error">{error}</Typography>;
    }

    if (!groupedData || Object.keys(groupedData).length === 0) {
        return <Typography variant="body1">No users found for this organization.</Typography>;
    }

    // Prepare rows for DataGrid
    const rows = groupedData ? Object.entries(groupedData).map(([instituteName, data], index) => ({
        id: `institute-${index}`,
        ...data,
        champion: `${data.champion.name} (${data.champion.total})`,
    })) : [];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                columns={columns}
                rows={rows}
                slots={{ toolbar: GridToolbar }}
                slotProps={{ toolbar: { csvOptions: { utf8WithBom: true } } }}
            />
        </div>
    );
};

export default Institutions;
