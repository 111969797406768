import React, { useState } from 'react';
import { Typography, Grid, Button, Tabs, Tab, Box, Alert } from '@mui/material';
import FileUploadHandler from '../../components/FileUploadHandlerForExam';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import { useLanguage } from '../../contexts/languageContext';
import { uploadPastExams, uploadSyllabus } from '../../requests/personalization';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../hooks/useLoader';
import Header from '../../components/Header';

const TabValues = {
    EXAMS: 'exams',
    SYLLABUS: 'syllabus'
};

const UploadResources = () => {
    const { openSnackBar } = useSnackBar();
    const { openLoader, closeLoader } = useLoader();
    const { languageData } = useLanguage();
    const navigate = useNavigate();

    const [tabValue, setTabValue] = useState(TabValues.EXAMS);
    const [examFile, setExamFile] = useState(null);
    const [syllabusFile, setSyllabusFile] = useState(null);
    const [isExamUploaded, setIsExamUploaded] = useState(false);
    const [isSyllabusUploaded, setIsSyllabusUploaded] = useState(false);

    const handleExamFileSelection = (fileContent) => {
        if (!isExamUploaded) {
            setExamFile(fileContent);
        }
    };

    const handleSyllabusFileSelection = (fileContent) => {
        if (!isSyllabusUploaded) {
            setSyllabusFile(fileContent);
        }
    };

    const uploadExamFile = async () => {
        if (!examFile) {
            openSnackBar(SnackbarTypes.NO_TEXT.field);
            return;
        }
        openLoader();
        try {
            await uploadPastExams([examFile]);
            setIsExamUploaded(true);
            setExamFile(null);
            openSnackBar(SnackbarTypes.SUCCESS.field);
        } catch (error) {
            console.error('Error uploading past exam:', error);
            openSnackBar(SnackbarTypes.ERROR.field);
        } finally {
            closeLoader();
        }
    };

    const uploadSyllabusFile = async () => {
        if (!syllabusFile) {
            openSnackBar(SnackbarTypes.NO_TEXT.field);
            return;
        }
        openLoader();
        try {
            await uploadSyllabus([syllabusFile]);
            setIsSyllabusUploaded(true);
            setSyllabusFile(null);
            openSnackBar(SnackbarTypes.SUCCESS.field);
        } catch (error) {
            console.error('Error uploading syllabus:', error);
            openSnackBar(SnackbarTypes.ERROR.field);
        } finally {
            closeLoader();
        }
    };

    const handleFinish = () => {
        navigate('/personal?r=1');
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Header />
            <Grid container spacing={2} marginTop={5} px={4} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {languageData?.onboarding?.upload_resources?.title || 'Upload Resources'}
                    </Typography>
                    <Typography variant="subtitle1" align="center" gutterBottom>
                        {languageData?.onboarding?.upload_resources?.subtitle || 
                            'Uploading past tests and syllabus documents helps us understand your teaching style and preferences, enabling us to provide more personalized and effective suggestions.'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="success">
                        {languageData?.onboarding?.upload_resources?.data_privacy_alert || 
                            'We will not train or use your data in any way. Your privacy is important to us.'}
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <Tabs value={tabValue} onChange={handleTabChange} centered>
                        <Tab label={languageData?.onboarding?.upload_resources?.upload_past_exams || 'Past Exams'} value={TabValues.EXAMS} />
                        <Tab label={languageData?.onboarding?.upload_resources?.upload_syllabus || 'Syllabus'} value={TabValues.SYLLABUS} />
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    {tabValue === TabValues.EXAMS && (
                        <>
                            <FileUploadHandler
                                onInput={handleExamFileSelection}
                                maxFiles={2}
                                maxCharsLimit={30000}
                                disabled={isExamUploaded}
                            />
                            <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={uploadExamFile}
                                    disabled={!examFile || isExamUploaded}
                                >
                                    {languageData?.onboarding?.upload_resources?.upload || 'Upload Exam File'}
                                </Button>
                            </Box>
                        </>
                    )}
                    {tabValue === TabValues.SYLLABUS && (
                        <>
                            <FileUploadHandler
                                onInput={handleSyllabusFileSelection}
                                maxFiles={1}
                                maxCharsLimit={50000}
                                disabled={isSyllabusUploaded}
                            />
                            <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={uploadSyllabusFile}
                                    disabled={!syllabusFile || isSyllabusUploaded}
                                >
                                    {languageData?.onboarding?.upload_resources?.upload || 'Upload Syllabus File'}
                                </Button>
                            </Box>
                        </>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Box mt={4} display="flex" justifyContent="center" alignItems="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFinish}
                            sx={{ paddingX: 4 }}
                        >
                            {languageData?.onboarding?.upload_resources?.finish || 'Finish'}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default UploadResources;
