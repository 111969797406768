import React, { useState, useMemo } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Chip } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import generateEnglishPDF from '../assets/tutorials/generate.pdf';
import autoGradeEnglishPDF from '../assets/tutorials/grade.pdf';
import Header from '../components/Header';
import { languageCodes } from '../contexts/languageContext';
import { useLanguage } from '../contexts/languageContext';

const tutorials = [
    {
        title: "Features Demo",
        link: 'https://www.youtube.com/embed/BpHhr8sWTdE',
        language: languageCodes.english
    },
    {
        title: "הרשמה למערכת ומילוי פרטים אישיים",
        link: 'https://www.youtube.com/embed/JeCY_uKok7Y',
        language: languageCodes.hebrew
    },
    {
        title: "אפשרויות להעלאת תוכן",
        link: 'https://www.youtube.com/embed/qv0XF6IsSoM',
        language: languageCodes.hebrew
    },
    {
        title: "יצירת בחינה תוך מספר שניות עם בינה מלאכותית",
        link: 'https://www.youtube.com/embed/0wD05xr5ALg',
        language: languageCodes.hebrew
    },
    {
        title: "יצירת בחינה מבוססת מיומנויות",
        link: 'https://www.youtube.com/embed/gH97kS_-q4U',
        language: languageCodes.hebrew
    },
    {
        title: "עריכה והוספת שאלות לבחינה",
        link: 'https://www.youtube.com/embed/O0UcdGMpvvU',
        language: languageCodes.hebrew
    },
    {
        title: "צפייה מקדימה ומענה על בחינה",
        link: 'https://www.youtube.com/embed/Rc7Ms5WWwRk',
        language: languageCodes.hebrew
    },
    {
        title: "ציון אוטומטי ומשוב מפורט למבחנים",
        link: 'https://www.youtube.com/embed/nTAJauXeoaw',
        language: languageCodes.hebrew
    },
    {
        title: "כיצד לנתח ציונים ומשובים",
        link: 'https://www.youtube.com/embed/1GLHJN13Xw4',
        language: languageCodes.hebrew
    },
    {
        title: "כניסת תלמידים",
        link: 'https://www.youtube.com/embed/wlTbvlFyDqM?si=VBcx3HcLBqakXDbH',
        language: languageCodes.hebrew
    },
];

const Tutorials = () => {
    const { language } = useLanguage();
    const [selectedLanguage, setSelectedLanguage] = useState(language);

    const filteredTutorials = useMemo(() => {
        setSelectedLanguage(prev => language);
        return tutorials.filter(tutorial => tutorial.language === selectedLanguage);
    }, [selectedLanguage, language]);

    return (
        <div>
            <Helmet>
                <title>StudyWise | Tutorials</title>
                <meta name="description" content="Explore our tutorials to master the AI-powered exam generator. Learn how to create personalized exams, customize questions, and optimize your workflow with our step-by-step guides." />
            </Helmet>
            <Header />
            <Container style={{ padding: '30px' }}>
                {filteredTutorials.length === 0 ? (
                    <Typography variant="body1" color="textSecondary">
                        Tutorials for this language are coming soon...
                    </Typography>
                ) : (
                    <Grid container spacing={3}>
                        {filteredTutorials.map((tutorial, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    <CardContent
                                        style={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            direction: tutorial.language === languageCodes.hebrew ? 'rtl' : 'ltr',
                                        }}
                                    >
                                        <Typography variant="h5" component="h2">
                                            {tutorial.title}
                                        </Typography>
                                        <div style={{ marginTop: '10px' }}>
                                            <Chip label={tutorial.language === languageCodes.hebrew ? 'עברית' : 'English'} />
                                        </div>
                                        {tutorial.link.includes('youtube.com/embed') ? (
                                            <iframe
                                                width="100%"
                                                height="200"
                                                src={tutorial.link}
                                                title={tutorial.title}
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                style={{ marginTop: '16px' }}
                                            />
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                href={tutorial.link}
                                                target="_blank"
                                                style={{ marginTop: '16px' }}
                                            >
                                                {/* todo: language data */}
                                                {tutorial.language === languageCodes.hebrew ? 'לצפייה במדריך' : 'View Tutorial'}
                                            </Button>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Container>
        </div>
    );
};

export default Tutorials;
