import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { moeILAuth } from '../requests/moeAuth';
import { homepage, onboarding } from '../components/menu/MenuLinks';
import { HttpStatusCode } from 'axios';
import { useUser } from '../hooks/useUser';
import useHistory from '../hooks/useHistory';
import { handleRedirectAfterAuth } from '../utils/auth';
function NotAuthorized({ error }) {
    return (
        <div>
            <p>Not Authorized</p>
            <p>{error}</p>
        </div>
    );
}

export default function IdmMoeILRedirect() {
    const userContext = useUser();
    const historyContext = useHistory();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const [authorized, setAuthorized] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState('');

    useEffect(() => {
        if (!code || !state) {
            setAuthorized(false);
            setErrorMsg('Invalid code or state');
        } else {
            setAuthorized(true);
            const payload = preAuthPayload();
            moeILAuth(code, state, payload).then(res => {
                const redirectUrl = localStorage.getItem('redirectUrl');
                if (redirectUrl) {
                    const { idm_data, ...studentData } = res.data; // Extract the idm_data and do not save it in the local storage
                    studentData.timestamp = new Date().getTime(); // Add timestamp to the student data
                    studentData.isstudent = idm_data?.isstudent === 'Yes';
                    localStorage.setItem('studentData', JSON.stringify(studentData));
                    localStorage.removeItem('redirectUrl');
                    window.open(redirectUrl, '_self');
                    return;
                }
                const user = res?.data;
                userContext.login(user)
                historyContext.resetHistory()
                const isstudent = user?.idm_data?.isstudent === 'Yes' || user?.identity_provider_payload?.idm_data?.isstudent === 'Yes';
                if (isstudent) {
                    navigate('/student/history')
                    return
                }
                else if (res.status === HttpStatusCode.Ok) {
                    // user found
                    handleRedirectAfterAuth(navigate)
                } else if (res.status === HttpStatusCode.Created) {
                    // user created
                    navigate(onboarding);
                } else {
                    setErrorMsg(`Error while authenticating - ${res?.data}`);
                    setAuthorized(false);
                }
            }).catch(err => {
                console.error(err);
                setErrorMsg(`Error while authenticating - ${err?.response?.data}`);
                setAuthorized(false);
            });
        }
    }, [code, state])

    if (!authorized) {
        return <NotAuthorized error={errorMsg} />
    }

    return (
        <div>
            <p>Redirecting...</p>
        </div>
    );
}

/**
 * 
 * @returns {orgId?: string, userData:{roles: string[], exams: number}}
 */
function preAuthPayload() {
    const payload = {};

    const orgId = localStorage.getItem('orgId');
    if (orgId) {
        payload.orgId = orgId;
        sessionStorage.removeItem('orgId');
    }

    const token = localStorage.getItem('token');
    if (token) {
        payload.token = token;
        sessionStorage.removeItem('token');
    }
    return payload;
}