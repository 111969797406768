// React
import React, { useEffect, useState } from 'react';
// Components
import { Button, CircularProgress, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import CardList from '../components/CardList'
import Header from '../components/Header';
// Hooks
import useSnackBar from '../hooks/useSnackBar';
import useHistory from '../hooks/useHistory';
import { useDebounce } from '../hooks/useDebounce';
import { colors, paddingPageStyle } from '../utils/customTheme';
import { Add as AddIcon } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { SnackbarTypes } from '../utils/snackbarTypes';
import { useLanguage } from '../contexts/languageContext';
import GoogleAds from '../components/GoogleAds';
import { useUser } from '../hooks/useUser';

const HistoryPage = () => {
    const { user } = useUser()
    const { history, deleteFromHistory } = useHistory();
    const { languageData, isRTL } = useLanguage();
    const { openSnackBar } = useSnackBar();
    const [historyData, setHistoryData] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(true);
    const debouncedSearchExam = useDebounce(searchExam, 300);
    const [showExamsWithResponses, setShowExamsWithResponses] = useState(false); // New state for the toggle

    useEffect(() => {
        if (showExamsWithResponses) {
            setHistoryData(history.filter(exam => exam.responses_count > 0));
        } else {
            setHistoryData(history);
        }
    }, [showExamsWithResponses, history]);

    useEffect(() => {
        if (history?.length > 0) {
            if (!initialized) {
                setLoading(true);
                setHistoryData(history);
                setInitialized(true);
                setLoading(false);
            } else {
                setHistoryData(history);
            }
        } else {
            setInitialized(false);
            setLoading(false);
        }
    }, [history, initialized]);

    const deleteExam = (examId) => {
        openSnackBar('DELETE_EXAM');
        deleteFromHistory(examId).then(() => {
            openSnackBar('EXAM_DELETED');
        }).catch(err => {
            openSnackBar('ERROR');
        });
    };

    /** @param {string input} */
    function searchExam(input) {
        if (input) {
            const loweredCaseInput = input.toLowerCase();
            const filteredHistory = history.filter(exam => exam.title.toLowerCase().includes(loweredCaseInput));
            setHistoryData(filteredHistory);
        } else {
            setHistoryData(history);
        }
    }

    return (
        <>
            <Helmet>
                <title>StudyWise | History</title>
                <meta name="description" content="Review your past exams and track your progress with our AI-powered exam generator. Easily revisit and analyze previous exams to enhance future learning outcomes." />
            </Helmet>
            <Header />
            {user?._id === process.env.REACT_APP_TZAHI_ID && <GoogleAds />}
            <div style={{ ...paddingPageStyle, padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='h5' sx={{ fontWeight: 700, fontSize: '2rem', margin: '10px 0 10px 0', direction: isRTL ? 'rtl' : 'ltr' }}>
                    {languageData?.history?.title?.replace('{amount}', historyData?.length ?? 0) ?? ''}
                </Typography>

                {/* Position the switch below the title in the center */}
                <FormControlLabel
                    control={
                        <Switch
                            checked={showExamsWithResponses}
                            onChange={() => setShowExamsWithResponses(prev => !prev)}
                            color="primary"
                        />
                    }
                    label={languageData?.history?.show_with_responses || "Show exams with responses"}
                    sx={{ marginBottom: '20px', alignSelf: 'center' }} // Center the switch
                />

                {/* Search bar: */}
                <TextField
                    label="Search Exam"
                    placeholder="Search Exam"
                    variant="outlined"
                    onInput={e => debouncedSearchExam(e.target.value)}
                    sx={{ marginBottom: '20px', minWidth: '-webkit-fill-available' }}
                    autoComplete='off'
                    inputProps={{ autoFocus: false }}
                />

                {/* Exams list: */}
                {historyData?.length > 0 ? (
                    <CardList exams={historyData} deleteExam={deleteExam} />
                ) : (loading ?
                    <CircularProgress disableShrink size={'4rem'} sx={{ color: colors.main, marginTop: '3rem' }} />
                    :
                    <>
                        <p>{languageData?.history?.no_exams || "No exams available"}</p>
                        <Button
                            variant='contained'
                            sx={{ borderRadius: '50%', width: '100px', height: '100px', marginTop: '5rem' }}
                            onClick={() => window.location.href = "/exam"}
                        >
                            <AddIcon sx={{ fontSize: '3rem' }} />
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};

export default HistoryPage;
