import { CircularProgress, TextField } from "@mui/material"
import { useState, useEffect, useRef } from "react"
import { useDebounce } from "../../hooks/useDebounce";
import StudentsList from "./StudentsList";
import { paddingPageStyle } from "../../utils/customTheme";
import Header from "../../components/Header";
import { Helmet } from "react-helmet-async";
import { useMyStudents } from "../../contexts/MyStudentsContext";
import { useLoader } from "../../hooks/useLoader";

function MyStudents() {
    const { students, fetchMyStudents } = useMyStudents();
    const { openLoader, closeLoader } = useLoader();
    const [filteredStudents, setFilteredStudents] = useState([])
    const [loading, setLoading] = useState(true);
    const initialized = useRef(false);
    const debouncedSearchStudent = useDebounce(searchStudent, 300);

    useEffect(() => {
        if (!students.length && !initialized.current) {
            openLoader();
            fetchMyStudents().then(fetchedStudents => {
                if (fetchedStudents.length) {
                    setFilteredStudents(fetchedStudents)
                    setLoading(false)
                }
                initialized.current = true
            }).finally(() => closeLoader())
        } else {
            setFilteredStudents(students)
            setLoading(false)
        }
    }, [students])

    /** @param {string} input */
    function searchStudent(input) {
        if (input) {
            const loweredCaseInput = input.toLowerCase()
            const filtered = students.filter(student => student?.studentInfo?.name?.toLowerCase().includes(loweredCaseInput))
            setFilteredStudents(filtered)
        } else {
            setFilteredStudents(students)
        }
    }

    return (<>
        <Helmet>
            <title>My Students - StudyWise</title>
            <meta name="description" content="Manage and review your students' progress and performance with StudyWise's AI-powered exam generator." />
            <meta name="keywords" content="students, manage students, review students, student performance, AI exam generator, AI test" />
            <meta name="author" content="StudyWise" />
        </Helmet>
        <Header />
        <div style={{ ...paddingPageStyle, padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

            <TextField label="Search Student" placeholder="Search Student" variant="outlined"
                onInput={e => debouncedSearchStudent(e.target.value)}
                sx={{ marginBottom: '20px', minWidth: '-webkit-fill-available' }}
                autoComplete='off'
                inputProps={{ autoFocus: false }}
            />

            {loading ? <CircularProgress /> : <StudentsList students={filteredStudents} />}
        </div>
    </>
    )
}

export default MyStudents;