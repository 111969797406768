import api, { lti } from './api';
import { processResponsePromise } from './utils';

export function assignExamToActivity(examId) {
    if (!examId)
        return Promise.reject('Exam ID is required');
    return processResponsePromise(api.post(`/exams/${examId}/assign-activity`));
}

export function getLMSData() {
    return processResponsePromise(lti.get('/lms-data'));
}

export function getExamActivityProfile(examId) {
    
    if (!examId)
        return Promise.reject('Exam ID is required');
    return processResponsePromise(api.get(`/exams/${examId}/exam-activity-profile`));
}

export function duplicateAndAssignExamActivity(examId) {
    if (!examId)
        return Promise.reject('Exam ID is required');
    return processResponsePromise(api.post(`/exams/${examId}/duplicate-and-assign-activity`));
}