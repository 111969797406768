import ROLES from "../../utils/roles";

export const signupLinks = {
    il_teacher: `https://exam-generator.com/signup?token=${process.env.REACT_APP_TEACHER_REGISTER_TOKEN}`,
    us_teacher: `https://exam-generator.com/signup?token=${process.env.REACT_APP_US_TEACHER_REGISTER_TOKEN}`,
    student: `https://exam-generator.com/signup?token=${process.env.REACT_APP_STUDENT_REGISTER_TOKEN}`,
    yarden: `https://exam-generator.com/signup?token=${process.env.REACT_APP_YARDEN_REGISTER_TOKEN}`,
    linoy: `https://exam-generator.com/signup?token=${process.env.REACT_APP_LINOY_REGISTER_TOKEN}`,
    tzahi: `https://exam-generator.com/signup?token=${process.env.REACT_APP_TZAHI_REGISTER_TOKEN}`,
}
export const tokensConfig = [
    {
        name: 'IL_TEACHER',
        key: 'token',
        token: process.env.REACT_APP_TEACHER_REGISTER_TOKEN,
        link: signupLinks.il_teacher,
        roles: [ROLES.IL_TEACHER.label],
        exams: 30
    },
    {
        name: 'US_TEACHER',
        key: 'token',
        token: process.env.REACT_APP_US_TEACHER_REGISTER_TOKEN,
        link: signupLinks.us_teacher,
        roles: [ROLES.US_TEACHER.label],
        exams: 10
    },
    {
        name: 'Student',
        key: 'token',
        token: process.env.REACT_APP_STUDENT_REGISTER_TOKEN,
        link: signupLinks.student,
        roles: [ROLES.STUDENT.label],
        exams: 10
    },
    {
        name: 'Yarden',
        key: 'token',
        token: process.env.REACT_APP_YARDEN_REGISTER_TOKEN,
        link: signupLinks.yarden,
        roles: [ROLES.YARDEN.label, ROLES.STUDENT.label],
        exams: 10
    },
    {
        name: 'Linoy',
        key: 'token',
        token: process.env.REACT_APP_LINOY_REGISTER_TOKEN,
        link: signupLinks.linoy,
        roles: [ROLES.LINOY.label, ROLES.STUDENT.label],
        exams: 10
    },
    {
        name: 'Tzahi',
        key: 'token',
        token: process.env.REACT_APP_TZAHI_REGISTER_TOKEN,
        link: signupLinks.tzahi,
        roles: [ROLES.TZAHI.label],
        exams: 10
    }
]

export function isValidToken(token) {
    return tokensConfig.some(tokenObj => tokenObj.token === token)
}
