import React, { useMemo } from 'react';
import { Box, Card, CardContent, Grid, Typography, LinearProgress } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';
import CustomLatex from '../../components/CustomLatex';
import { questionsType } from '../../utils/examOptions';

const TopCorrectAnswers = ({ students, examJson }) => {
    const { languageData, isRTL } = useLanguage();

    const topCorrectAnswersMemoized = useMemo(() => {
        const correctCount = {};

        if (!students || !examJson || students.length === 0 || !examJson.questions || examJson.questions.length === 0) {
            return [];
        }

        students.forEach(student => {
            student.answers.forEach(answer => {
                const question = examJson.questions.find(q => q._id === answer.question_id);
                if (!question) return;

                const isMultipleOrFillInType =
                    question.type === questionsType.multiple || question.type === questionsType.fill_in;
                const isOpenOrGraphType =
                    question.type === questionsType.open || question.type === questionsType.graph;

                // Condition for multiple and fill-in type questions
                if (isMultipleOrFillInType && question.correctAnswers.includes(answer.answer)) {
                    correctCount[answer.question_id] = (correctCount[answer.question_id] || 0) + 1;
                }
                // Condition for open and graph type questions
                else if (isOpenOrGraphType && (answer.grade > (question.grade * 0.5))) {
                    correctCount[answer.question_id] = (correctCount[answer.question_id] || 0) + 1;
                }
            });
        });

        if (Object.keys(correctCount).length === 0) {
            return [];
        }

        const sortedCorrectQuestions = Object.keys(correctCount).sort((a, b) => correctCount[b] - correctCount[a]);

        const result = sortedCorrectQuestions.map(questionId => ({
            question: examJson.questions.find(question => question._id === questionId),
            corrects: correctCount[questionId],
        }));

        return result.filter(item => (item.corrects / students.length) > 0.5).slice(0, 5);
    }, [students, examJson]);

    if (!topCorrectAnswersMemoized || topCorrectAnswersMemoized.length === 0) {
        return null;
    }

    return (
        <Grid container spacing={2} p={2}>
            {topCorrectAnswersMemoized.map((correctAnswer, index) => {
                const correctPercentage = ((correctAnswer.corrects / students.length) * 100).toFixed(0);

                return (
                    <Grid item xs={12} key={index}>
                        <Card elevation={3}>
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        textAlign: isRTL ? 'right' : 'left',
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        {languageData?.quiz?.answer_label} {' '} {index + 1}
                                    </Typography>

                                    <Box sx={{ width: '33%', display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <Box sx={{ width: '100%' }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    mb: 0.5,
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{ fontWeight: 'bold', color: 'text.secondary' }}
                                                >
                                                    {correctAnswer.corrects}/{students.length}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ fontWeight: 'bold', color: 'success.main' }}
                                                >
                                                    {correctPercentage}%
                                                </Typography>
                                            </Box>

                                            <LinearProgress
                                                variant="determinate"
                                                value={parseFloat(correctPercentage)}
                                                color="success"
                                                sx={{
                                                    height: 6,
                                                    borderRadius: 5,
                                                    '& .MuiLinearProgress-bar': {
                                                        borderRadius: 5,
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                                <Box sx={{ display: 'flex', textAlign: isRTL ? 'right' : 'left', marginTop: 2 }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        <CustomLatex>{correctAnswer.question.question}</CustomLatex>
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default TopCorrectAnswers;
