import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, IconButton, MenuItem, Select } from '@mui/material';
import { useAffiliates } from '../../contexts/Dashboard/AffiliatesContext';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Delete, Link, Edit } from '@mui/icons-material';
import { DeleteConfirmationPopver } from '../../components/DeleteConfirmationPopover';
import ROLES from '../../utils/roles';

const AffiliatesDashboard = () => {
    const { affiliates, createAffiliate, deleteAffiliate, updateAffiliate, copyAffiliateLink, fetchAffiliates } = useAffiliates();
    const [editingAffiliate, setEditingAffiliate] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const affiliateToDelete = useRef(null);

    useEffect(() => {
        fetchAffiliates();
    }, []);

    const handleOpenDialog = (affiliate = { name: '', exams: 10 }) => {
        setEditingAffiliate(affiliate);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditingAffiliate(null);
    };

    const handleSaveAffiliate = (newAffiliate) => {
        if (editingAffiliate._id) {
            updateAffiliate(editingAffiliate._id, newAffiliate);
        } else {
            createAffiliate(newAffiliate);
        }
    };
    const handleDeleteClick = (e, affiliate) => {
        affiliateToDelete.current = affiliate;
        setAnchorEl(e.currentTarget);
    }
    const handleDeleteAffiliate = () => {
        deleteAffiliate(affiliateToDelete.current?._id);
        setAnchorEl(null);
    }

    return (
        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h4" gutterBottom>Affiliate Dashboard</Typography>

            <Button variant="contained" onClick={() => handleOpenDialog()}>New Affiliate</Button>

            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Exams</TableCell>
                            <TableCell>Roles</TableCell>
                            <TableCell>Token</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {affiliates.map((affiliate) => (
                            <TableRow key={affiliate._id}>
                                <TableCell>{affiliate.name}</TableCell>
                                <TableCell>{affiliate.exams}</TableCell>
                                <TableCell>{affiliate?.roles?.join(', ')}</TableCell>
                                <TableCell>{affiliate.token}</TableCell>
                                <TableCell sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <IconButton onClick={() => copyAffiliateLink(affiliate.token)}><Link /></IconButton>
                                        <IconButton onClick={() => handleOpenDialog(affiliate)}><Edit /></IconButton>
                                    </Box>
                                    <IconButton onClick={e => handleDeleteClick(e, affiliate)}><Delete color="error" /></IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <AffiliateDialog
                open={openDialog}
                onClose={handleCloseDialog}
                affiliate={editingAffiliate}
                onSave={handleSaveAffiliate}
            />
            <DeleteConfirmationPopver
                type="dialog"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                onCancel={() => setAnchorEl(null)}
                onDelete={handleDeleteAffiliate}
            />
        </div>
    );
};

export default AffiliatesDashboard;


// New Dialog Component
const AffiliateDialog = ({ open, onClose, affiliate, onSave }) => {
    const [newAffiliate, setNewAffiliate] = useState(affiliate);
    const rolesOptions = useMemo(() => Object.values(ROLES).filter(role => role.label).map(role => ({ label: role.label, value: role.label })), [])

    useEffect(() => {
        setNewAffiliate(affiliate);
    }, [affiliate]);

    const handleSave = () => {
        onSave(newAffiliate);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { minWidth: '30rem' } }}>
            <DialogTitle>{affiliate?._id ? 'Edit Affiliate' : 'New Affiliate'}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', mt: 2 }}>
                    <TextField
                        label="Affiliate Name"
                        value={newAffiliate?.name}
                        onChange={(e) => setNewAffiliate({ ...newAffiliate, name: e.target.value })}
                        fullWidth
                    />
                    <TextField
                        label="Exams"
                        value={newAffiliate?.exams}
                        onChange={(e) => setNewAffiliate({ ...newAffiliate, exams: parseInt(e.target.value) })}
                        fullWidth
                        type="number"
                    />
                    <Select
                        id='roles'
                        label='Roles:'
                        multiple
                        value={newAffiliate?.roles ?? []}
                        onChange={e => setNewAffiliate({ ...newAffiliate, roles: e.target.value })}
                        variant='filled'
                    >
                        {rolesOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

