import React, { useState, useEffect, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, AreaChart, Area, PieChart, Pie, Cell } from 'recharts';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

export const chartTypes = {
    lineChart: 'lineChart',
    barChart: 'barChart',
    areaChart: 'areaChart',
    pieChart: 'pieChart'
};

export const durationTypes = {
    daily: 'daily',
    weekly: 'weekly',
    monthly: 'monthly'
};

/**
 * Renders a dashboard chart component.
 * @param {Object} props - The component props.
 * @param {string} props.orgId - The organization ID.
 * @param {string} props.title - The chart title.
 * @param {Function} props.fetchData - The function to fetch data for the chart.
 * @param {{daily: {[date]: number}[], weekly: {[date]: number}[], monthly: {[date]: number}[]}} props.data - The chart data. each item in the array should have a different key with a date value and a value key.
 * @param {string} props.dataKey - The key to access the data in the chart.
 * @param {string} props.chartColor - The color of the chart line.
 * @returns {JSX.Element} The rendered DashboardChart component.
 */
const DashboardChart = ({ orgId, title, fetchData, data, dataKey, nameKey, chartColor, chartType = chartTypes.barChart, allTime = false }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [duration, setDuration] = useState(durationTypes.weekly);

    useEffect(() => {
        if (orgId && fetchData && !data) {
            setLoading(true);
            fetchData(orgId).then(() => {
                setError(null);
            }).catch((e) => {
                console.error(`Error fetching ${title.toLowerCase()} data:`, e);
                setError(`Failed to fetch ${title.toLowerCase()}.`);
            }).finally(() => setLoading(false));
        }
    }, [orgId]);

    const handleChange = (event) => {
        setDuration(event.target.value);
    };

    const getData = useCallback(() => {
        if (allTime) {
            return data || [];
        }
        switch (duration) {
            case durationTypes.daily:
                return data?.daily || [];
            case durationTypes.weekly:
                return data?.weekly || [];
            case durationTypes.monthly:
                return data?.monthly || [];
            default:
                return [];
        }
    }, [duration, data, allTime]);

    const getChartComponent = useCallback(() => {
        switch (chartType) {
            case chartTypes.lineChart:
                return LineChartComponent;
            case chartTypes.barChart:
                return BarChartComponent;
            case chartTypes.areaChart:
                return AreaChartComponent;
            case chartTypes.pieChart:
                return PieChartComponent;
            default:
                return BarChartComponent;
        }
    }, [chartType]);

    return (
        <Box sx={{ minHeight: '420px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            {!allTime && <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel id="chart-type-label"></InputLabel>
                <Select
                    labelId="chart-type-label"
                    value={duration}
                    onChange={handleChange}
                >
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
            </FormControl>}

            {error ? <Typography color="error">{error}</Typography> :
                (loading && !data ? <CircularProgress /> :
                    <ResponsiveContainer width="100%" minHeight="300px">
                        {getChartComponent()({ data: getData(), dataKey, chartColor, nameKey })}
                    </ResponsiveContainer>
                )
            }
        </Box>
    );
};

function BarChartComponent({ data, dataKey, chartColor }) {
    return (
        <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={'date'} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={dataKey} fill={chartColor} />
        </BarChart>
    );
};

function LineChartComponent({ data, dataKey, chartColor }) {
    return (
        <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={'date'} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={dataKey} stroke={chartColor} activeDot={{ r: 8 }} />
        </LineChart>
    );
};

function AreaChartComponent({ data, dataKey, chartColor }) {
    return (
        <AreaChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={'date'} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Area type="monotone" dataKey={dataKey} stroke={chartColor} fill={chartColor} />
        </AreaChart>
    );
};

function PieChartComponent({ data, dataKey, chartColor, nameKey = 'name' }) {
    return (
        <PieChart>
            <Tooltip />
            <Legend />
            <Pie data={data} dataKey={dataKey} nameKey={nameKey} cx="50%" cy="50%" outerRadius={100} fill={chartColor} label >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
            </Pie>
        </PieChart>
    );
}

export default DashboardChart;
