import React, { useMemo } from 'react';
import { AppBar, Toolbar, Box, Typography, useMediaQuery } from '@mui/material';
import { questionsType } from '../../utils/examOptions';
import { isMobile } from '../../utils';

const QuestionCircle = ({ question, index, circleSize, isAnswered }) => (
    <Box
        key={question._id}
        sx={{
            width: circleSize,
            height: circleSize,
            borderRadius: '50%',
            backgroundColor: isAnswered ? '#2e7d32' : '#f5f5f5',
            border: '1px solid',
            borderColor: isAnswered ? '#2e7d32' : '#e0e0e0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: isAnswered ? '#ffffff' : '#666666',
            cursor: 'pointer',
            fontSize: isMobile ? 13 : 14,
            fontWeight: 500,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: isAnswered ? '#1b5e20' : '#eeeeee'
            },
            '&:active': {
                transform: 'scale(0.95)'
            }
        }}
        onClick={() => {
            const element = document.getElementById(question._id);
            if (element) {
                const headerOffset = isMobile ? 100 : 80;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }}
    >
        {index + 1}
    </Box>
);

const ExamHeader = ({ questions, answers }) => {
    const isTablet = useMediaQuery('(max-width:900px)');

    const totalQuestions = questions.length;

    const answeredMap = useMemo(() => {
        return questions.reduce((map, q) => {
            const isAnswered = answers[q._id] !== undefined &&
                answers[q._id] !== "" &&
                (q.type !== questionsType.nested ||
                    (Array.isArray(answers[q._id]) &&
                        answers[q._id].length === q.nested_questions.length &&
                        answers[q._id].every(ans => ans.answer)));
            map[q._id] = isAnswered;
            return map;
        }, {});
    }, [questions, answers]);

    const answeredQuestions = useMemo(
        () => Object.values(answeredMap).filter(Boolean).length,
        [answeredMap]
    );

    const progress = useMemo(
        () => (answeredQuestions / totalQuestions) * 100,
        [answeredQuestions, totalQuestions]
    );

    const circleSize = useMemo(() => {
        if (isMobile) return totalQuestions > 10 ? 24 : 28;
        if (isTablet) return totalQuestions > 15 ? 26 : 30;
        return 32;
    }, [isMobile, isTablet, totalQuestions]);

    return (
        <>
            <AppBar
                elevation={0}
                position="fixed"
                sx={{
                    backgroundColor: '#ffffff',
                    borderBottom: '1px solid #e0e0e0'
                }}
            >
                <Toolbar sx={{ minHeight: { xs: 56, sm: 64 } }}>
                    <Box
                        sx={{
                            width: '100%',
                            margin: '0 auto',
                            px: { xs: 1, sm: 2, md: 3 },
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: { xs: 1, sm: 0 },
                            justifyContent: 'space-between',
                            alignItems: { xs: 'flex-start', sm: 'center' }
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography
                                variant={isMobile ? "subtitle1" : "h6"}
                                sx={{
                                    color: '#1a1a1a',
                                    fontWeight: 600
                                }}
                            >
                                {answeredQuestions}/{totalQuestions}
                            </Typography>
                            <Typography
                                variant={isMobile ? "body2" : "body1"}
                                sx={{
                                    color: progress === 100 ? '#2e7d32' : '#666666',
                                    fontWeight: 500
                                }}
                            >
                                ({progress.toFixed(0)}%)
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: { xs: 0.5, sm: 1 },
                                flexWrap: 'wrap',
                                justifyContent: { xs: 'flex-start', sm: 'flex-end' }
                            }}
                        >
                            {questions.map((question, index) => (
                                <QuestionCircle
                                    key={question._id}
                                    question={question}
                                    index={index}
                                    circleSize={circleSize}
                                    isAnswered={answeredMap[question._id]}
                                />
                            ))}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar sx={{ minHeight: { xs: 112, sm: 64 } }} />
        </>
    );
};

export default ExamHeader;
