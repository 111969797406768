import React, { useCallback } from 'react';
import { Tooltip, AppBar, Toolbar, IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ShareIcon from '@mui/icons-material/Share';
import SettingsButton from '../../components/Settings/SettingsButton';
import ShareButton from '../../components/Share/ShareButton';
import { useLanguage } from '../../contexts/languageContext';
import ChooseLanguageBtn from '../../components/ChooseLanguageBtn';
import HighlightButton from '../../components/HighlightButton/HighlightButton';
import { useLobby } from '../../contexts/LobbyContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useUser } from '../../hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '../../utils';

const PreviewHeader = ({ examId, handleSaveSettings, examSettings }) => {
    const { isRTL, languageData } = useLanguage();
    const { lobbyStatus } = useLobby();
    const { isFromLMS } = useUser();
    const navigate = useNavigate();

    const handleViewExam = () => {
        window.open(`/quiz?id=${examId}`, '_blank');
    };

    const viewExamButton = useCallback(() => {
        const title = (lobbyStatus?.auto_grade == false
            ? languageData?.preview?.tooltips?.start_auto_grade
            : languageData?.preview?.tooltips?.view_exam) ?? "Preview your exam";
        return lobbyStatus?.auto_grade == false ? (
            <HighlightButton>
                <Tooltip title={title} placement="bottom">
                    <Button
                        endIcon={<VisibilityIcon />}
                        sx={{ mr: 1 }}
                        variant={isMobile ? "text" : "contained"}
                        color="primary"
                        onClick={handleViewExam}
                    >
                        {!isMobile && title}
                    </Button>
                </Tooltip>
            </HighlightButton>
        ) : (
            <Tooltip title={title} placement="bottom">
                <Button
                    endIcon={<VisibilityIcon />}
                    sx={{ mr: 1 }}
                    variant={isMobile ? "text" : "contained"}
                    color="primary"
                    onClick={handleViewExam}
                >
                    {!isMobile && title}
                </Button>
            </Tooltip>
        );
    }, [lobbyStatus, languageData]);

    return (
        <AppBar elevation={1} position="fixed" sx={{ bgcolor: 'white' }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                {/* Left Side: New Button */}
                {!isMobile && (
                    <Button
                        sx={{ ml: 1 }}
                        variant="text"
                        color="primary"
                        onClick={() => navigate('/exam')}
                        endIcon={<AddIcon />}
                    >
                        {languageData?.menu?.exam ?? "Exam"}
                    </Button>
                )}

                {/* Right Side: Existing Elements */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SettingsButton button onSubmit={handleSaveSettings} examSettings={examSettings} />
                    {viewExamButton()}
                    <Tooltip title={languageData.preview.tooltips.share_exam} placement="bottom">
                        <span>
                            <ShareButton
                                icon={<ShareIcon />}
                                forceDesktopStyle
                                isRTL={isRTL}
                                languageData={languageData?.preview}
                                examId={examId}
                                fromLMS={isFromLMS}
                            />
                        </span>
                    </Tooltip>
                    <ChooseLanguageBtn />
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default PreviewHeader;
