import React, { useState, useEffect } from 'react';
import { colors } from '../../utils/customTheme';
import { Box } from '@mui/material';

const FlickeringDot = ({ isSuccess }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible((prev) => !prev);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Box
            sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                mx: 1,
                backgroundColor: isSuccess ? colors.success : colors.error,
                opacity: isVisible ? 1 : 0.5,
                transition: 'opacity 0.3s ease-in-out',
                boxShadow: `0 0 4px ${isSuccess ? colors.success : colors.error}`,
            }}
        />
    );
};

export default FlickeringDot;
