
import React from "react";
import { questionsType } from '../../utils/examOptions'
import EditableMultipleQuestion from "./EditableMultipleQuestion";
import EditableOpenQuestion from "./EditableOpenQuestion";
import EditableGraphQuestion from "./EditableGraphQuestion";
import { useState } from "react";
import { EMPTY_STRING } from "../../utils";
import EditableNestedQuestion from "./EditableNestedQuestion";

function EditableQuestionTypeManager({ type, allQuestionsProps, openQuestionProps, closeQuestionProps, GraphQuestionProps, nestedProps }) {
    const [gradeError, setGradeError] = useState(false);
    const handleGradeChange = (event) => {
        let newGrade = event.target.value;
        if (newGrade === EMPTY_STRING)
            newGrade = 0
        const isValid = validateGrade(newGrade);
        if (isValid)
            allQuestionsProps.onGradeChange(allQuestionsProps.questionIndex, newGrade);
    };

    function validateGrade(newGrade) {
        newGrade = parseFloat(newGrade);
        if (!isNaN(newGrade) && newGrade >= 0) {
            setGradeError(false);
            return true; // valid grade
        }
        else {
            setGradeError(true);
            return false; // invalid grade
        }
    };

    return (
        <>
            {type === questionsType.open && (
                <EditableOpenQuestion
                    {...openQuestionProps}
                    {...allQuestionsProps}
                    handleGradeChange={handleGradeChange}
                    gradeError={gradeError}
                />
            )}
            {type === questionsType.graph && (
                <EditableGraphQuestion
                    {...GraphQuestionProps}
                    {...allQuestionsProps}
                    handleGradeChange={handleGradeChange}
                    gradeError={gradeError}
                />
            )}
            {(type === questionsType.multiple || type === questionsType.fill_in) && (
                <EditableMultipleQuestion
                    {...closeQuestionProps}
                    {...allQuestionsProps}
                    handleGradeChange={handleGradeChange}
                    gradeError={gradeError}
                />
            )}
            {(type === questionsType.nested) && (
                <EditableNestedQuestion
                    {...nestedProps}
                    {...allQuestionsProps}
                    validateGrade={validateGrade}
                    gradeError={gradeError}
                />
            )}
        </>
    )
}

export default EditableQuestionTypeManager;