import api from "./api";
import { processResponsePromise } from "./utils";

class AffiliatesAPI {
    static getAll() {
        return processResponsePromise(api.get('/affiliates'))
    }
    static create(affiliate) {
        return processResponsePromise(api.post('/affiliates', affiliate))
    }
    static update(id, affiliate) {
        return processResponsePromise(api.put(`/affiliates/${id}`, affiliate))
    }
    static delete(id) {
        return processResponsePromise(api.delete(`/affiliates/${id}`))
    }
}

export default AffiliatesAPI;