import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CustomLatex from './CustomLatex';
import { useLanguage } from '../contexts/languageContext';
import { Divider, IconButton, TextField, Tooltip } from '@mui/material';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check'; // Import Check icon
import { customToFixed, renderTextWithBreakLines } from '../utils';
import { colors } from '../utils/customTheme';
import { useDebounce } from '../hooks/useDebounce';

/**
 * Renders an AI indicator component.
 * @param {Object} props - The component props.
 * @param {string} props.label - The label for the Typography component.
 * @param {Object} props.data - The data object containing indicator and explanation.
 * @param {Array} props.data.indicator - The indicator
 * @param {number} props.data.questionGrade - question grade
 * @returns {JSX.Element|null} The rendered ChipBox component.
 */
const ChipBox = ({ label, data, onBreakdownChange, blockEdit = false }) => {
    const [edit, setEdit] = React.useState(false);

    if (!label || !data || !data?.indicator || (Array.isArray(data?.indicator) && data?.indicator?.length === 0)) {
        return null;
    }

    return (
        <Grid container mt={2} spacing={1}>
            <Grid item xs={12}>
                <Box sx={{ border: '1px solid #ccc', borderRadius: '5px', p: 2 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: 'bold',
                            }}
                        >
                            {label}
                        </Typography>
                        {!blockEdit && Array.isArray(data?.indicator) &&
                            <Tooltip title={edit ? "Save" : "Edit score"} arrow placement='top-end'>
                                <IconButton onClick={() => setEdit(prevEdit => !prevEdit)}>
                                    {edit ? (
                                        <CheckIcon sx={{ backgroundColor: colors.success, color: "white", borderRadius: "50%", padding: "4px" }} />
                                    ) : (
                                        <EditIcon sx={{ backgroundColor: colors.secondary, color: "white", borderRadius: "50%", padding: "4px" }} />
                                    )}
                                </IconButton>
                            </Tooltip>}
                    </div>
                    <IndicatorBreakdown
                        breakdown={data.indicator}
                        questionGrade={data.questionGrade}
                        isEdit={edit}
                        onChange={onBreakdownChange} // Pass the function to IndicatorBreakdown
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

const IndicatorBreakdown = ({ breakdown, questionGrade, isEdit, onChange }) => {
    const { languageData } = useLanguage();
    const [editedBreakdown, setEditedBreakdown] = useState(breakdown)
    const debouncedOnChange = useDebounce(onChange, 200)

    useEffect(() => {
        if (JSON.stringify(editedBreakdown) !== JSON.stringify(breakdown)) {
            setEditedBreakdown(breakdown);
        }
    }, [breakdown]);


    const handleEditChange = (index, field, value) => {
        const updatedBreakdown = editedBreakdown.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );
        // state that updates every change, and pass the updated breakdown back to the review page, after debounce (to avoid multiple state changes).
        setEditedBreakdown(updatedBreakdown);
        debouncedOnChange(updatedBreakdown);
    };

    if (!Array.isArray(editedBreakdown)) {
        return (<Typography variant="subtitle1">
            {renderTextWithBreakLines(editedBreakdown ?? '')}
        </Typography>
        );
    }

    return editedBreakdown.map((item, index) => {
        const allocated = parseFloat((questionGrade * (item.allocated_percentage / 100)).toFixed(1));
        const awarded = parseFloat((questionGrade * (item.awarded_percentage / 100)).toFixed(1));
        let awardedLabel = customToFixed(awarded);

        return (
            <React.Fragment key={index}>
                <Grid container sx={{ marginBottom: '8px', gap: '16px' }}>
                    <Grid item xs={12} md={5} lg={5}>
                        <Typography variant="body2">
                            <span style={{ fontWeight: 'bold' }}>{languageData?.review?.response?.concept}</span>&nbsp;&nbsp;
                            <CustomLatex>{item.indicator}</CustomLatex>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5.2} lg={5.2}>
                        <Typography variant="body2">
                            <span style={{ fontWeight: 'bold' }}>{languageData?.review?.response?.explanation}</span>&nbsp;&nbsp;
                            {isEdit ? (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={item.reason}
                                    onChange={(e) => handleEditChange(index, 'reason', e.target.value)}
                                    multiline
                                    maxRows={3}
                                />
                            ) : (
                                <CustomLatex>{item.reason}</CustomLatex>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={1.4} lg={1.4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', direction: 'ltr' }} >
                        {isEdit ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    type="number"
                                    inputProps={{ max: allocated, min: 0 }}
                                    value={awarded}
                                    onChange={(e) => {
                                        if (e.target.value < 0) e.target.value = 0;
                                        if (e.target.value > allocated) e.target.value = allocated;
                                        const newAwardedPercentage = (e.target.value / questionGrade) * 100;
                                        handleEditChange(index, 'awarded_percentage', newAwardedPercentage);
                                    }}
                                    size="small"
                                    sx={{ maxWidth: '70px' }}
                                />
                                <Typography variant="body1">
                                    &nbsp;{"/ " + allocated}
                                </Typography>
                            </div>
                        ) : (
                            <CircularProgressWithLabel
                                percentage={(awarded / allocated) * 100}
                                label={
                                    <>
                                        {awardedLabel}/{allocated} <br /> {languageData?.preview?.points_label}
                                    </>
                                }
                            />
                        )}
                    </Grid>
                </Grid>
                {index < editedBreakdown?.length - 1 && <Divider sx={{ my: '10px' }} />}
            </React.Fragment>
        );
    });
};


export default ChipBox;
