import axios from 'axios';
import { getJWTHeaders } from '../utils/JWT_functions';

axios.defaults.withCredentials = true;

// Base URLs for API instances
export const openPages = ['login', 'signup', 'blog'];

// Create API Instances
export const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL || 'http://localhost:5000/api/v1'
});

export const lti = axios.create({
    baseURL: process.env.REACT_APP_SERVER_LTI_URL || 'http://localhost:5000/lti',
});

// Apply the interceptors to both instances
setupInterceptors(api);
setupInterceptors(lti);

export default api;

// Common Interceptor Setup Function
function setupInterceptors(instance) {
    instance.interceptors.request.use(
        (config) => {
            const tokenHeader = getJWTHeaders();
            config.headers = {
                ...config.headers,
                ...tokenHeader,
            };
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (
                error.response &&
                !openPages.some((path) => window.location.pathname.includes(path)) &&
                error.response.data &&
                ['expired', 'invalid', 'revoked'].some((reason) =>
                    error.response.data?.msg?.toLowerCase()?.includes(reason)
                )
            ) {
                // If page is not login or signup and Token expired or invalid or revoked, then:
                window.location.href = '/login'; // Redirect to login
            }
            return Promise.reject(error);
        }
    );
};