import React, { useMemo, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, FormLabel, TextField } from '@mui/material';
import { questionsType, questionsTypesOptions } from '../../utils/examOptions';
import { languageCodes, useLanguage } from '../../contexts/languageContext';
import CustomSelect from '../../components/CustomSelect';

const AddQuestionDialog = ({ open, onClose, onAddQuestion }) => {
  const [questionType, setQuestionType] = useState(questionsTypesOptions[0].value);
  const [grade, setGrade] = useState(5);
  const { isRTL, languageData, language } = useLanguage()
  languageCodes
  // Removed graph question type - if you want to add it back, remove this line 
  const questionTypes = useMemo(() => questionsTypesOptions.filter(option => option.value !== questionsType.graph && option.value !== questionsType.nested), []);
  const questionsOptions = useMemo(() => questionTypes?.map(option => {
    const label = language === languageCodes.hebrew ? option.hebrewLabel : (language === languageCodes.arabic ? option.arabicLabel : option.label);
    return { value: option.value, label: label }
  }), [questionTypes, language]);

  const handleAddQuestion = (type) => {
    onAddQuestion({ type: type, grade });
    onClose();
  };
  const handleClose = () => {
    handleAddQuestion(null);
  }
  const handleChangeGrade = (e) => {
    let grade = parseFloat(e.target.value)
    if (grade > 100) {
      grade = 100;
    } else if (grade < 0) {
      grade = 0;
    }
    setGrade(grade);
  }

  return (
    <Dialog open={open} onClose={handleClose} dir={isRTL ? 'rtl' : 'ltr'} PaperProps={{ sx: { minWidth: '20%' } }}>
      <DialogTitle>{languageData?.add_dialog?.title}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" sx={{ width: '100%' }}>
          <FormLabel component="legend">{languageData?.add_dialog?.subtitle}</FormLabel>
          <CustomSelect
            selectProps={{
              'aria-label': "questionType",
              name: "questionType"
            }}
            value={questionType}
            onChangeHandler={(e) => setQuestionType(e.target.value)}
            options={questionsOptions}
          />
          <br />
          <FormLabel component="legend">{languageData?.preview?.grade_label}</FormLabel>
          <TextField
            type='number'
            value={grade}
            onChange={handleChangeGrade}
            inputProps={{ max: '100' }}
          />
        </FormControl>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{languageData?.add_dialog?.cancel}</Button>
        <Button variant="contained" onClick={() => handleAddQuestion(questionType)} disabled={!questionType}>{languageData?.add_dialog?.add}</Button>
      </DialogActions>
    </Dialog >
  );
};

export default AddQuestionDialog;
