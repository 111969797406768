import React, { useEffect, useState } from 'react';
import { Typography, TextField } from '@mui/material';
import Graph from '../../components/Recharts/Graph';
import { useLanguage } from '../../contexts/languageContext';
import { useDebounce } from '../../hooks/useDebounce'
import { renderTextWithBreakLines } from '../../utils';

const GraphQuestion = ({ questionId, title, functions, onAnswer, savedAnswer }) => {
    const [answer, setAnswer] = useState(savedAnswer);
    const { languageData, isRTL } = useLanguage()
    const debouncedOnAnswer = useDebounce(onAnswer)

    useEffect(() => {
        if (savedAnswer)
            setAnswer(savedAnswer)
    }, [savedAnswer])

    const handleAnswerChange = (e) => {
        const newAnswer = e.target.value
        setAnswer(newAnswer);
        debouncedOnAnswer(questionId, newAnswer)
    }

    return (
        <div dir={isRTL ? 'rtl' : 'ltr'}>
            <Graph fnStrings={functions} />
            <Typography variant="h6" gutterBottom>
                {renderTextWithBreakLines(title)}
            </Typography>
            <TextField
                label={languageData?.quiz?.answer_label ?? ''}
                value={answer}
                onChange={handleAnswerChange}
                fullWidth
                multiline
                minRows={3}
                maxRows={15}
                variant="outlined"
                margin="normal"
                error={!answer}
            />
        </div>
    );
};

export default GraphQuestion;
