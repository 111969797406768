import React, { useState } from 'react';
import { Avatar, Popover, Box, Typography } from '@mui/material';
import { questionsType } from '../../utils/examOptions';
import { useLanguage } from '../../contexts/languageContext';
import { renderTextWithBreakLines } from '../../utils';

const AvatarList = ({ students, mistakenQuestion }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverData, setPopoverData] = useState(null);
    const { isRTL } = useLanguage();

    const handleClick = (student, answer, event) => {
        setPopoverData({ student, answer });
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const getInitials = (name) => {
        if (!name) return '';
        const nameParts = name.split(' ');
        if (nameParts.length > 1) {
            return nameParts[0][0] + nameParts[1][0]; // First letter of first and last name
        } else {
            return nameParts[0][0]; // First letter of the name
        }
    };

    // Filter students who answered the mistaken question incorrectly
    const mistakenStudents = students.filter(student => {
        const answer = student.answers.find(a => a.question_id === mistakenQuestion.question._id);
        if (!answer) return false;

        const question = mistakenQuestion.question;

        // Logic for different question types
        if (question.type === questionsType.multiple || question.type === questionsType.fill_in) {
            // For multiple choice or fill-in type questions, check if the answer is incorrect
            return !question.correctAnswers.includes(answer.answer);
        } else if (question.type === questionsType.open || question.type === questionsType.graph) {
            // For open or graph type questions, check if the grade is below half
            return answer.grade <= (question.grade * 0.5);
        }

        return false; // Default case: if the question type doesn't match, return false
    });

    return (
        <Box sx={{
            display: 'flex',
            gap: 1,
            mt: 1,
            flexWrap: 'nowrap',
            overflowX: 'auto',
            maxWidth: '100%',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        }}>
            {mistakenStudents.map((student, index) => {
                const answer = student.answers.find(a => a.question_id === mistakenQuestion.question._id);
                if (!answer) return null;

                const initials = getInitials(student.student_name);

                return (
                    <Avatar
                        key={index}
                        sx={{
                            cursor: 'pointer',
                            marginBottom: 1,
                        }}
                        onClick={(e) => handleClick(student, answer, e)}
                    >
                        {initials}
                    </Avatar>
                );
            })}

            {/* Popover for displaying student answer */}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: isRTL ? 'right' : 'left',  // Conditional position based on isRTL
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: isRTL ? 'right' : 'left',  // Conditional position based on isRTL
                }}
            >
                <Box sx={{ p: 2, direction: isRTL ? 'rtl' : 'ltr' }}>
                    <Typography variant="h6" sx={{ textAlign: isRTL ? 'right' : 'left' }}>
                        {popoverData?.student?.student_name}
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: isRTL ? 'right' : 'left' }}>
                        {renderTextWithBreakLines(popoverData?.answer?.answer, false)}
                    </Typography>
                </Box>
            </Popover>
        </Box>
    );
};

export default AvatarList;
