// ManagerDashboardContext.js
import React, { createContext, useContext, useMemo, useState } from 'react';
import ROLES from '../utils/roles';
import { getOrgProfile, getOrgExamsSources, getOrgExamsTakenData, getOrgResponsesCount, getOrgTokensCount, getOrgUsers, getExamResponseSummary } from '../requests/organizations';
import { useUser } from '../hooks/useUser';
import { getIconColor } from '../utils';

const ManagerDashboardContext = createContext();

export const ManagerDashboardProvider = ({ children }) => {
    const [managerDashboardData, setManagerDashboardData] = useState({});
    const { user } = useUser()
    const hasAccess = useMemo(() => user?.is_admin || (user && user?.org_id && user?.roles?.some(role => role === ROLES.MANAGER.label)), [user]);

    async function fetchOrg(orgId) {
        return getOrgProfile(orgId).then(org => {
            if (org)
                setManagerDashboardData(prevData => ({ ...prevData, org: org }))
        })
    }

    async function fetchOrgUsers(orgId) {
        return getOrgUsers(orgId).then(users => {
            if (users && Array.isArray(users)) {
                setManagerDashboardData(prevData => ({ ...prevData, users: users }))
            }
        })
    }

    async function fetchExamTaken(orgId) {
        return getOrgExamsTakenData(orgId).then(examsTakenData => {
            if (examsTakenData) {
                setManagerDashboardData(prevData => ({ ...prevData, examsTaken: examsTakenData }))
            }
        })
    }

    async function fetchResponsesCount(orgId) {
        return getOrgResponsesCount(orgId).then(responsesCountData => {
            if (responsesCountData) {
                setManagerDashboardData(prevData => ({ ...prevData, responsesCount: responsesCountData }))
            }
        })
    }

    async function fetchTokensCount(orgId) {
        return getOrgTokensCount(orgId).then(tokensCountData => {
            if (tokensCountData) {
                setManagerDashboardData(prevData => ({ ...prevData, tokensCount: tokensCountData }))
            }
        })
    }

    async function fetchExamsSources(orgId) {
        return getOrgExamsSources(orgId).then(sourcesData => {
            /** @type {{_id: string (the source), count: number}} */
            if (sourcesData) {
                sourcesData = sourcesData.map(source => ({ ...source, color: getIconColor(source._id, 0.7) }));
                setManagerDashboardData(prevData => ({ ...prevData, sources: sourcesData }))
            }
        })
    }

    async function fetchExamResponseSummary(orgId) {
        return getExamResponseSummary(orgId).then(responseSummaryData => {
            /** @type {{_id: string, count: number, [otherProperties]: any}} */

            if (responseSummaryData)
                setManagerDashboardData(prevData => ({ ...prevData, examResponseSummary: responseSummaryData }));

        });
    }


    return (
        <ManagerDashboardContext.Provider value={{ managerDashboardData, hasAccess, fetchOrgUsers, fetchExamTaken, fetchResponsesCount, fetchTokensCount, fetchOrg, fetchExamsSources, fetchExamResponseSummary }}>
            {children}
        </ManagerDashboardContext.Provider>
    );
};

export const useManagerDashboard = () => useContext(ManagerDashboardContext);
