import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import FrequentlyMistakenQuestions from './FrequentlyMistakenQuestions ';
import TopCorrectAnswers from './TopCorrectAnswers';
import { useLanguage } from '../../contexts/languageContext';

const AnswersTabs = ({ students, examJson }) => {
    const { languageData } = useLanguage()
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ backgroundColor: 'white', mt: 1 }}>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                centered
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label={languageData?.review?.analytics?.title || 'Frequently Mistaken Questions'} />
                <Tab label={languageData?.review?.analytics?.correct || 'Top Correct Answers'} />
            </Tabs>
            <Box mt={2}>
                {activeTab === 0 && <FrequentlyMistakenQuestions students={students} examJson={examJson} />}
                {activeTab === 1 && <TopCorrectAnswers students={students} examJson={examJson} />}
            </Box>
        </Box>
    );
};

export default AnswersTabs;
