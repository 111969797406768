import React, { useState, useEffect } from "react";
// Hooks
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../../contexts/languageContext'
// MUI
import { Drawer, IconButton, CircularProgress, Typography, Divider, Button, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
// Components
import EditableQuestionTypeManager from "../PreviewPage/EditableQuestionTypeManager";
// Utils
import { requestExamHub } from "../../requests/hub"
import { generateId, isMobile } from "../../utils";
import Unseen from "../../components/Unseen";
import ChooseLanguageBtn from "../../components/ChooseLanguageBtn";
import { inputTypes } from "../../utils/configs";

function PreviewDrawer({ open, handleClose, examId }) {
    const [examJson, setExamJson] = useState(null);

    const { languageData } = useLanguage();
    const navigate = useNavigate();

    useEffect(() => {
        if (open) {
            const fetchExamJson = async () => {
                try {
                    const data = await requestExamHub(examId);
                    setExamJson(data);
                } catch (error) {
                    console.error("Error fetching exam JSON:", error);
                }
            };

            fetchExamJson();
        }
    }, [open, examId]);

    const handleRemix = () => {
        const { prompt, focus, questions, bloomsArray, difficulty, language, source, skills } = examJson.parameters;
        const isURL = source === inputTypes.url;
        const isMath = source === inputTypes.math;

        const inputSource = (source && (isURL || isMath)) ? source : inputTypes.text;

        const remixData = {
            prompt: prompt ?? '',
            focus: focus ?? '',
            questions: bloomsArray ?? questions ?? '',
            difficulty: difficulty ?? '',
            language: language ?? '',
            skills: skills ?? [],
            source: source ?? '',
        };

        sessionStorage.setItem('remix', JSON.stringify(remixData));
        navigate(`/exam?input=${inputSource}`);
    };

    const direction = examJson?.parameters?.language !== 'English' ? 'rtl' : 'ltr';

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiDrawer-paper': {
                    width: isMobile ? '90vw' : '60vw',
                    padding: '16px',
                    boxSizing: 'border-box',
                    direction: direction,
                },
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">{languageData?.hub?.table?.preview ?? 'Preview'}</Typography>
                <Box>
                    <ChooseLanguageBtn />
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>

            {!examJson && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </Box>
            )}

            {examJson && (
                <Box sx={{ overflowY: 'auto', height: '100%', paddingRight: '8px' }}>
                    <Typography variant="h5">{examJson.title}</Typography>
                    <Divider sx={{ marginY: '1rem' }} />
                    {examJson?.text && <Unseen text={examJson.text} />}
                    {examJson.questions?.map((question, index) => (
                        <React.Fragment key={index}>
                            <Typography key={generateId()} variant="h5" mt={2}>
                                {languageData.preview.question + ' ' + (index + 1)}
                            </Typography>
                            <EditableQuestionTypeManager
                                key={index}
                                type={question.type}
                                allQuestionsProps={{
                                    languageData: languageData.preview,
                                    questionIndex: index,
                                    title: question.question,
                                    grade: question.grade,
                                }}
                                nestedProps={{
                                    explanation: question.explanation,
                                    nested: question?.nested_questions,
                                    parentIndex: index + 1,
                                }}
                                openQuestionProps={{
                                    explanation: question.explanation,
                                    indicator: question?.indicator,
                                    source: question?.source_link,
                                }}
                                closeQuestionProps={{
                                    options: question.options,
                                    correctAnswer: question.correctAnswers?.[0],
                                }}
                                GraphQuestionProps={{
                                    explanation: question.explanation,
                                    functions: question.functions,
                                }}
                            />
                        </React.Fragment>
                    ))}
                </Box>
            )}

            <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'space-between', paddingTop: '16px' }}>
                <Button
                    onClick={handleRemix}
                    variant="contained"
                    color="primary"
                    startIcon={<SwitchAccessShortcutIcon />}
                    sx={{ gap: '5px' }}
                >
                    {languageData?.hub?.dialog?.remix_button_text ?? 'Remix'}
                </Button>
                <Button onClick={handleClose} color="primary">
                    {languageData?.hub?.dialog?.close_button_text ?? 'Close'}
                </Button>
            </Box>
        </Drawer>
    );
}

export default PreviewDrawer;
