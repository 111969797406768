import React, { useEffect, useMemo, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    CircularProgress,
    Box,
    Alert,
    Tooltip,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { assignExamToActivity, getLMSData, getExamActivityProfile, duplicateAndAssignExamActivity } from '../../requests/lti';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';

// todo: Consider split the assign dialong and share grades to LMS to two separate components/dialogs
function LMSShareDialog({ title, description, label, isRTL, languageData, open, onClose, lmsData, setLmsData, onConfirm, examId, syncGrades = false }) {
    const [loading, setLoading] = useState(!lmsData);
    const [assignBtnLoading, setAssignBtnLoading] = useState(false);
    const [error, setError] = useState(null);
    const [assigned, setAssigned] = useState(false);
    const [hasActivityData, setHasActivityData] = useState(false);
    const [activityData, setActivityData] = useState(null);
    const { openSnackBar } = useSnackBar();

    useEffect(() => {
        if (error) {
            // Display only for 5 secs
            setTimeout(() => {
                setError(null);
            }, 5000);
        }
    }, [error]);

    useEffect(() => {
        const fetchLMSData = async () => {
            if (!lmsData) {
                setLoading(true);
                setError(null);
                try {
                    const data = await getLMSData();
                    setLmsData(data);
                } catch (err) {
                    console.error('Failed to fetch LMS data:', err);
                    setError(languageData?.error_message ?? 'Failed to load LMS data.');
                } finally {
                    setLoading(false);
                }
            }
        };

        const checkActivityData = async () => {
            try {
                const activityData = await getExamActivityProfile(examId); // Fetch activity data for the exam
                setActivityData(activityData);
                setHasActivityData(!!activityData); // Check if activity data exists
            } catch (err) {
                console.error('Failed to fetch activity data:', err);
            }
        };
        if (open) {
            fetchLMSData();
            if (!syncGrades)
                checkActivityData(); // Check if there's existing activity data when dialog opens
        }
    }, [open]);

    const handleClick = () => {
        setAssignBtnLoading(true);
        const callback = syncGrades ? onConfirm : handleAssignActivity;
        callback().finally(() => {
            setAssignBtnLoading(false);
        });
    }

    const handleAssignActivity = async () => {
        return assignExamToActivity(examId).then(res => {
            openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field);
            setAssigned(true);
        }).catch(e => {
            setError(languageData?.assign_failed ?? "Failed to assign exam to LMS resource.");
            openSnackBar(SnackbarTypes.ERROR_CONTACT.field);
        }).finally(() => {
            setAssignBtnLoading(false);
            onConfirm();
        });
    };

    const handleDuplicate = async () => {
        return duplicateAndAssignExamActivity(examId).then(data => {
            if (data.new_id) {
                const newId = data.new_id;
                onConfirm();
                window.open(`/preview?id=${newId}`, '_self');
            }
        })
    };

    const assignBtnLabel = useMemo(() => label ?? (hasActivityData ? (languageData?.overwrite ?? 'Overwrite') : (languageData?.assign ?? 'Assign')), [label, languageData])

    return (
        <Dialog open={open} onClose={onClose} dir={isRTL ? 'rtl' : 'ltr'} maxWidth="sm" fullWidth>
            {assigned ? (
                <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, flexDirection: isRTL ? 'row-reverse' : 'row' }}>
                    <CheckCircleIcon color="success" />
                    {languageData?.assign_success ?? 'Exam assigned Successfully'}
                </DialogTitle>
            ) : (
                <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    {title ?? languageData?.title ?? 'Assign Exam to Activity'}
                </DialogTitle>
            )}
            <DialogContent>
                {error && (
                    <Alert severity="error" sx={{ mb: 1 }}>
                        {error}
                    </Alert>
                )}
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150, }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        {/* Display Course Name and Activity Data */}
                        <CourseName name={lmsData?.context?.title} languageData={languageData} />

                        {/* Activity Name */}
                        <ResourceName name={lmsData?.resource_link?.title} languageData={languageData} />

                        {/* Display Exam Activity Data if Available */}
                        {hasActivityData && activityData &&
                            <Box sx={{ mb: 2 }}>
                                <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    {languageData?.activity_exists_label ?? 'Exam is already assigned to:'}
                                </DialogTitle>
                                {activityData?.course_name && <CourseName name={activityData.course_name} languageData={languageData} />}
                                {activityData?.activity_name && <ResourceName name={activityData.activity_name} languageData={languageData} />}
                            </Box>
                        }

                        {/* Description */}
                        <Typography variant="body1" color="text.secondary">
                            {description ?? languageData?.description ?? 'Confirming will assign the exam to the resource you came from in the LMS.'}
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', padding: 2 }}>
                <Button onClick={onClose} color="error" variant="outlined" sx={{ minWidth: 100 }}>
                    {languageData?.cancel ?? 'Cancel'}
                </Button>

                {/* <Button onClick={handleDuplicate} color="secondary" variant="outlined" sx={{ minWidth: 100 }}>
                    {languageData?.duplicate ?? 'Duplicate'}
                </Button> */}

                <Button variant="contained" onClick={handleClick} color="primary" disabled={assignBtnLoading || loading} sx={{ minWidth: 100 }}>
                    {assignBtnLoading ? <CircularProgress size={24} /> : assignBtnLabel}
                </Button>

            </DialogActions>
        </Dialog >
    );
}

export default LMSShareDialog;

function ResourceName({ name, languageData }) {
    return < Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px', mb: 2 }}>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            {languageData?.resource_name_label ?? 'Resource Name:'}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {name || (
                <Tooltip title={languageData?.unavailable_resource ?? 'Resource name not available'}>
                    <span>{languageData?.unknown ?? 'Unknown'}</span>
                </Tooltip>
            )}
        </Typography>
    </Box >
}

function CourseName({ name, languageData }) {
    return <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            {languageData?.course_name_label ?? 'Course Name:'}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {name || (
                <Tooltip title={languageData?.unavailable_course ?? 'Course name not available'}>
                    <span>{languageData?.unknown ?? 'Unknown'}</span>
                </Tooltip>
            )}
        </Typography>
    </Box>
}