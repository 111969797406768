import { useState } from 'react';
import { TextField, Typography, MenuItem } from '@mui/material';
import { useDebounce } from '../../hooks/useDebounce';
import { isMobile, renderTextWithBreakLines } from '../../utils';
import CustomLatex from '../../components/CustomLatex';
import { Check } from '@mui/icons-material';

const EditableMultipleQuestion = ({ languageData, questionIndex, title, grade, options, correctAnswer, isEditing = false, onChange, handleGradeChange, gradeError }) => {
  const [gradeState, setGradeState] = useState(grade);
  const debouncedGradeChange = useDebounce(handleGradeChange, 200);

  const setQuestionTitle = (newQuestion) => {
    onChange(questionIndex, newQuestion, options, [correctAnswer]);
  }
  const setOptions = (newOptions) => {
    onChange(questionIndex, title, newOptions, [correctAnswer]);
  }
  const setQuestionCorrectAnswer = (newCorrectAnswer) => {
    onChange(questionIndex, title, options, [newCorrectAnswer]);
  }

  const handleCurrentGradeChange = (event) => {
    setGradeState(event.target.value);
    debouncedGradeChange(event);
  };

  return (
    <div>
      {isEditing ? (
        <>
          <TextField
            label={languageData.grade_label}
            value={gradeState ?? ""}
            onChange={e => handleCurrentGradeChange(e)}
            type='number'
            fullWidth
            variant="outlined"
            margin="normal"
            error={gradeError}
          />
          <TextField
            label={languageData.question_title}
            value={title}
            onChange={(e) => setQuestionTitle(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
            error={!title}
          />
          {options?.map((option, index) => (
            <TextField
              key={index}
              label={`${languageData?.question_option_label ?? 'Option'} ${index + 1}.`}
              value={option}
              onChange={(e) => {
                const newOptions = [...options];
                newOptions[index] = e.target.value;
                setOptions(newOptions);
              }}
              fullWidth
              variant="outlined"
              margin="normal"
              error={!option}
            />
          ))}
          <TextField
            select
            label={languageData.correct_answer}
            value={correctAnswer}
            onChange={(e) => setQuestionCorrectAnswer(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
            error={!correctAnswer}
          >
            {options?.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </>
      ) : (
        <>
          <Typography variant={isMobile ? "body1" : "h6"} gutterBottom sx={{ fontWeight: "bolder", margin: '10px 0px' }}>
            {renderTextWithBreakLines(title)}
          </Typography>
          {options?.map((option, index) => (
            <Typography key={index} variant="body1" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px'
            }}>
              {`${index + 1}. `}
              <CustomLatex>{option}</CustomLatex>
              {option === correctAnswer && (
                <Check color="success" fontSize="medium" />
              )}
            </Typography>
          ))}
        </>
      )
      }
    </div >
  );
};

export default EditableMultipleQuestion;
