import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import EditableOpenQuestion from './EditableOpenQuestion';
import { renderTextWithBreakLines } from '../../utils';


const EditableNestedQuestion = ({ languageData, questionIndex, grade, parentIndex, nested, title, isEditing = false, onChange, validateGrade, gradeError }) => {
    const [gradeState, setGradeState] = useState(nested.reduce((acc, question) => acc + (question.grade || 0), 0) ?? grade);
    // ! This is the only question type that onChange updates the grade as well

    const handleTitleChange = (newTitle) => {
        onChange(questionIndex, newTitle, nested);
    }

    const handleNestedGradeChange = (nestedIndex) => {
        // Updates the question's grade and the nested questions grades
        return (event) => {
            const newGrade = parseFloat(event.target.value) || 0

            const isValidGrade = validateGrade(newGrade)
            if (!isValidGrade) return

            const updatedNested = nested.map((item, index) => index === nestedIndex ? { ...item, grade: newGrade } : item); // Updates the nested grades

            const newTotalGrade = parseFloat(updatedNested.reduce((acc, question) => acc + (question.grade || 0), 0)).toFixed(2)

            setGradeState(newTotalGrade)
            onChange(questionIndex, title, updatedNested, newTotalGrade);
        }
    }

    const handleNestedChange = (nestedIndex, nestedObject) => {
        const updatedNested = nested.map((item, index) =>
            index === nestedIndex ? { ...item, indicator: nestedObject.indicator || item.indicator, question: nestedObject.title, explanation: nestedObject.explanation } : item
        );
        onChange(questionIndex, title, updatedNested);
    }

    return (
        <div>
            {isEditing ? (
                <>
                    <TextField
                        label={languageData.grade_label}
                        value={parseFloat(gradeState, 0).toFixed(2)}
                        type='number'
                        disabled
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        error={gradeError}
                    />
                    <TextField
                        label={languageData.question_title}
                        value={title ?? ""}
                        onChange={e => handleTitleChange(e.target.value)}
                        fullWidth
                        multiline
                        variant="outlined"
                        margin="normal"
                        error={!title}
                    />
                    {/* todo: fix this part */}
                    {nested.map((nestedQuestion, index) => (
                        <EditableOpenQuestion
                            key={index}
                            title={nestedQuestion.question}
                            languageData={languageData}
                            questionIndex={index}
                            grade={nestedQuestion.grade}
                            explanation={nestedQuestion.explanation}
                            isEditing={isEditing}
                            onChange={handleNestedChange}
                            handleGradeChange={handleNestedGradeChange(index)}
                            gradeError={gradeError}
                            indicator={nestedQuestion?.indicator}
                        />
                    ))}
                </>
            ) : (
                <>
                    <Typography variant="h6" gutterBottom sx={{ margin: '15px 0px' }}>
                        {renderTextWithBreakLines(title)}
                    </Typography>
                    {nested.map((nestedQuestion, index) => (
                        <div key={index} style={{ marginLeft: '20px' }}>
                            <Typography variant="h6" >
                                {parentIndex + "." + (index + 1) + ' - ' + nestedQuestion.grade + ' ' + languageData?.points_label}
                            </Typography>
                            <EditableOpenQuestion
                                languageData={languageData}
                                questionIndex={index}
                                title={nestedQuestion.question}
                                grade={nestedQuestion.grade}
                                explanation={nestedQuestion.explanation}
                                isEditing={false}
                                onChange={() => { }}
                                indicator={nestedQuestion?.indicator}
                                handleGradeChange={() => { }}
                                gradeError={false}
                            />
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default EditableNestedQuestion;
