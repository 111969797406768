import React, { useMemo } from 'react';
import { Card, CardContent, Typography, Box, Tooltip, Button } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/languageContext';
import { colors } from '../../utils/customTheme';

const TimeSavedCard = ({ examsCreated, examsEvaluated }) => {
    const navigate = useNavigate();
    const { languageData, isRTL } = useLanguage();

    // Handle potential null or undefined values for examsCreated and examsEvaluated
    const validatedExamsCreated = examsCreated ? examsCreated : 0;
    const validatedExamsEvaluated = examsEvaluated ? examsEvaluated : 0;

    // Memoize hasExams
    const hasExams = useMemo(() => validatedExamsCreated > 0 || validatedExamsEvaluated > 0, [validatedExamsCreated, validatedExamsEvaluated]);

    // Memoize timeSaved
    const timeSaved = useMemo(() => (validatedExamsCreated * 3) + (validatedExamsEvaluated * 7), [validatedExamsCreated, validatedExamsEvaluated]);

    return (
        <Card sx={{ bgcolor: colors.primary, color: 'white', borderRadius: 3 }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h5" component="div">
                        {hasExams ? languageData?.lobby?.time_saved : languageData?.lobby?.start_saving}
                    </Typography>
                    {/* {hasExams && (
                        <Tooltip
                            title="Number of exams created x 3 hours + Number of exams evaluated x 7 hours"
                            placement="top"
                            arrow
                        >
                            <HelpOutlineIcon sx={{ ml: 1, cursor: 'pointer', color: 'black', fontSize: '1.2rem' }} />
                        </Tooltip>
                    )} */}
                </Box>
                {hasExams ? (
                    <div dir={isRTL ? 'rtl' : 'ltr'} style={{ textAlign: 'center' }}>
                        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
                            {timeSaved} {languageData?.lobby?.hours}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: colors.sub }}>
                            {languageData?.time_saved
                                ? languageData.time_saved.replace("{validatedExamsCreated}", validatedExamsCreated).replace("{validatedExamsEvaluated}", validatedExamsEvaluated)
                                : `Based on ${validatedExamsCreated} exams created and ${validatedExamsEvaluated} exams evaluated.`}
                        </Typography>
                    </div>
                ) : (
                    <Button
                        variant="contained"
                        onClick={() => navigate('/exam')}
                        sx={{ mt: 2, bgcolor: colors.main, color: 'white' }}
                    >
                        {languageData?.lobby?.cta}
                    </Button>
                )}
            </CardContent>
        </Card>
    );
};

export default TimeSavedCard;