import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const GoogleAds = () => {
    useEffect(() => {
        // Execute the ad script and log after mounting
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (error) {
            console.error("Google Ads failed to load", error);
        }
    }, []);

    return (
        <>
            <Helmet>
                <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8150904372533699"
                    crossOrigin="anonymous"
                ></script>
            </Helmet>
            {/* Banner */}
            <ins
                className="adsbygoogle"
                style={{ display: 'inline-block', width: '728px', height: '90px' }}
                data-ad-client="ca-pub-8150904372533699"
                data-ad-slot="4948454826"
            ></ins>
        </>
    );
};

export default GoogleAds;
