import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Grid, Button, Typography, Paper, Divider, Chip, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getExamForQuizResults, getExamResponse } from '../../requests/quiz';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import useSnackBar from '../../hooks/useSnackBar';
import { useLanguage } from '../../contexts/languageContext';
import { getTempToken } from '../../requests/token';
import StudentDetails from '../Review/StudentDetails';
import { languages, questionsType } from '../../utils/examOptions';
import ChipBox from '../../components/ChipBox';
import NoAnswerProvidedChip from '../../components/Chips/NoAnswerProvided';
import Graph from '../../components/Recharts/Graph';
import Header from '../../components/Header';
import DisplayMultipleChoice from '../Review/DisplayMultipleChoice';
import { convertISOToDDMMYYYY } from '../../utils/dateHelpers';
import CustomLatex from '../../components/CustomLatex';
import { useUser } from '../../hooks/useUser';
import ROLES from '../../utils/roles';
import Unseen from '../../components/Unseen';
import { colors } from '../../utils/customTheme';
import { getSeverityForGrade, getSeverityStyles } from '../../utils/severity';
import { renderTextWithBreakLines } from '../../utils';

const ResultsPage = () => {
    const [response, setResponse] = useState(null);
    const [exam, setExam] = useState(null);
    const token = useRef(null);
    const navigate = useNavigate();

    const { isRTL, languageData, fetchLanguageWithToken, changeToHebrew } = useLanguage();
    const { openSnackBar } = useSnackBar();
    const { user, isLoggedIn } = useUser()
    const resultsLanguageData = languageData?.review?.response;

    const examId = sessionStorage.getItem('examId');
    const responseId = sessionStorage.getItem('responseId');
    const empty_field = '----';

    const generalFeedback = response?.general_feedback ?? empty_field;
    const totalGrade = parseFloat(response?.grade);
    const maxGrade = useMemo(() => exam?.questions.reduce((acc, curr) => acc + parseFloat(curr.grade), 0), [exam]);

    useEffect(() => {
        getTempToken({ responseId, examId }).then(tempToken => {
            if (tempToken) {
                token.current = tempToken;
                fetchLanguageWithToken(tempToken);
                getExamResponse(examId, responseId, tempToken).then(res => {
                    if (res) {
                        setResponse(res);
                    } else {
                        openSnackBar(SnackbarTypes.ERROR.field);
                    }
                }).catch(e => {
                    openSnackBar(SnackbarTypes.ERROR.field);
                });
                getExamForQuizResults(examId, tempToken).then(res => {
                    if (res) {
                        if (res.parameters?.language === languages.Hebrew)
                            changeToHebrew()
                        setExam(res);
                    } else {
                        openSnackBar(SnackbarTypes.ERROR.field);
                    }
                }).catch(e => {
                    openSnackBar(SnackbarTypes.ERROR.field);
                });
            } else {
                openSnackBar(SnackbarTypes.ERROR.field);
            }
        }).catch(e => {
            openSnackBar(SnackbarTypes.ERROR.field);
        });
    }, []);

    useEffect(() => {
        isLoggedIn().then(loggedIn => {
            if (loggedIn) {
                // pass
            }
        });
    }, [isLoggedIn]);

    const handleCreateAnotherExam = () => {
        navigate('/exam');
    };

    const loadNestedQuestions = (nested_questions, nested_answers, languageData, parentIndex) => {
        nested_answers = Array.isArray(nested_answers) ? nested_answers : [];

        const nestedArray = [];

        for (const [index, question] of nested_questions.entries()) {
            const nestedAnswer = nested_answers.find(ans => ans.questionId === question._id || ans.question_id === question._id) || {};

            if (question.type === questionsType.open) {
                nestedArray.push(
                    <>
                        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
                            {`${resultsLanguageData?.question_label ?? ""} ${parentIndex + 1}.${index + 1}`}
                            {(nestedAnswer?.answer?.trim() === "" || !nestedAnswer?.answer) && (
                                <NoAnswerProvidedChip text={languageData?.review?.response?.no_answer_provided ?? "No answer provided"} />
                            )}
                        </Typography>
                        <Typography>
                            {renderTextWithBreakLines(question.question)}
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ backgroundColor: colors.bg, padding: 1, borderRadius: 2, mt: 1 }}>
                            {renderTextWithBreakLines(nestedAnswer?.answer)}
                        </Typography>
                        <ChipBox
                            label={languageData?.explanation}
                            data={{
                                indicator: nestedAnswer?.indicator_breakdown ?? question?.indicator ?? question?.explanation,
                                questionGrade: question.grade
                            }}
                            blockEdit={true}
                        />
                        {nestedAnswer?.teacher_feedback && (
                            <Grid item xs={12} sm={10} md={10} lg={12}>
                                <Typography variant="body1" fontWeight="bold">{resultsLanguageData.teacher_feedback}</Typography>
                                <Typography variant="body1">
                                    {renderTextWithBreakLines(nestedAnswer?.teacher_feedback)}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={3} sm={1} md={1} lg={1}>
                            <Typography variant="body1" fontWeight="bold">{languageData?.grade}</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row', direction: 'ltr', width: 'fit-content' }}>
                                <Typography variant="body1">{nestedAnswer?.grade ?? ''}</Typography>
                                <Typography variant="body1">{' ' + `/ ${parseInt(question.grade)}`}</Typography>
                            </div>
                        </Grid>
                        <Divider sx={{ marginY: 3 }} />
                    </>
                );
            }
        }
        return nestedArray;
    };


    return (exam && response && resultsLanguageData) && (
        <div>
            <Header />
            <div dir={isRTL ? 'rtl' : 'ltr'} style={{ maxWidth: '75rem', margin: 'auto', padding: '0 20px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={10} sm={6} md={6} lg={6}>
                        <StudentDetails student={{ ...response, grade: totalGrade, maximumGrade: maxGrade, date: response?.date }} />
                    </Grid>
                    {exam.instructions && (
                        <Grid item xs={12} sm={12} md={12} lg={12} mb={"20px"}>
                            <Unseen text={exam.instructions} />
                        </Grid>
                    )}
                    {exam.text && (
                        <Grid mb={"20px"}>
                            <Unseen text={exam.text} />
                        </Grid>
                    )}
                    {exam?.questions.map((question, index) => {
                        const studentAnswer = response.answers?.find(answer => answer.questionId === question._id || answer.question_id === question._id);
                        const severity = getSeverityForGrade(studentAnswer?.grade, question.grade);
                        const { borderColor, backgroundColor } = getSeverityStyles(severity);

                        return (
                            <Grid item xs={12} key={question._id}>
                                <Paper sx={{ padding: '16px', marginBottom: '16px' }}>
                                    <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
                                        {`${resultsLanguageData?.question_label ?? ""} ${index + 1}`}
                                        {((studentAnswer?.answer?.trim() === "" || !studentAnswer?.answer) && question.type !== questionsType.nested) && (
                                            <NoAnswerProvidedChip text={languageData?.review?.response?.no_answer_provided ?? "No answer provided"} />
                                        )}
                                    </Typography>
                                    {question?.image?.url && <img src={question.image.url} alt="" style={{ objectFit: 'contain', maxHeight: '300px', width: '100%' }} />}
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: "bolder", py: 1 }}>
                                        {renderTextWithBreakLines(question.question)}
                                    </Typography>
                                    {question.type === questionsType.graph && question.functions && <Graph fnStrings={question.functions} />}
                                    {(question.type === questionsType.multiple || question.type === questionsType.fill_in) && (
                                        <DisplayMultipleChoice
                                            options={question.options}
                                            correctAnswers={question.correctAnswers}
                                            studentAnswer={studentAnswer?.answer}
                                        />
                                    )}
                                    {question.type === questionsType.nested &&
                                        loadNestedQuestions(question.nested_questions, studentAnswer?.nested_answers, resultsLanguageData, index)
                                    }
                                    {(question.type === questionsType.open || question.type === questionsType.graph) && (
                                        <>
                                            <Typography variant="body1" gutterBottom sx={{ backgroundColor: colors.bg, padding: 1, borderRadius: 2, mt: 1 }}>
                                                {renderTextWithBreakLines(studentAnswer?.answer)}
                                            </Typography>
                                            <ChipBox
                                                label={resultsLanguageData?.explanation}
                                                data={{ indicator: studentAnswer?.indicator_breakdown ?? question?.indicator ?? question?.explanation, questionGrade: question.grade }}
                                                blockEdit={true}
                                            />
                                        </>
                                    )}
                                    <Grid container flexDirection='column' alignItems="start" pt={2}>
                                        {studentAnswer?.teacher_feedback && studentAnswer?.teacher_feedback !== empty_field && (
                                            <Alert
                                                variant="outlined"
                                                icon

                                                severity={severity}
                                                style={{ borderColor, backgroundColor, marginBottom: 20 }}
                                            >
                                                <Typography variant="h6" gutterBottom>
                                                    {resultsLanguageData.teacher_feedback}
                                                </Typography>
                                                <Typography variant="body1">
                                                    <CustomLatex>{studentAnswer?.teacher_feedback}</CustomLatex>
                                                </Typography>
                                            </Alert>
                                        )}
                                        <Grid item xs={3} sm={1} md={1} lg={1}>
                                            <Typography variant="h6">{resultsLanguageData.grade}</Typography>
                                            <div style={{ display: 'flex', flexDirection: 'row', direction: 'ltr' }}>
                                                <Typography variant="body1">{studentAnswer?.grade ?? ''}</Typography>
                                                <Typography variant="body1">{' ' + `/ ${parseInt(question.grade)}`}</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid >
                        );
                    })}
                    {generalFeedback && generalFeedback !== empty_field && (
                        <Grid item xs={12}>
                            <Paper sx={{ padding: '16px', marginBottom: '16px' }}>
                                <Typography variant="h6">{resultsLanguageData.general_feedback_label}</Typography>
                                <Typography variant="body1">{generalFeedback}</Typography>
                            </Paper>
                        </Grid>
                    )}
                    {/* {user?.roles?.includes(ROLES.STUDENT.label) && (
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleCreateAnotherExam}>
                                + Create another exam
                            </Button>
                        </Grid>
                    )} */}
                </Grid >
            </div>
        </div>
    );
};

export default ResultsPage;
