import { bloomTypes } from './examOptions';

export const getBloomsLabel = (BloomsLevel) => {
    if (!BloomsLevel)
        return '';
    if (BloomsLevel.value && BloomsLevel.label) {
        return BloomsLevel.label.charAt(0).toUpperCase() + BloomsLevel.label.slice(1)
    }
    return BloomsLevel.charAt(0).toUpperCase() + BloomsLevel.slice(1)
}

export const getBloomsLevel = (level) => {
    if (!level)
        return bloomTypes.knowledge;
    if (level.value) {
        return level.value
    }
    return level
}

export const getBloomLevelColor = (level) => {
    switch (level) {
        case bloomTypes.knowledge:
            return "info";
        case bloomTypes.comprehension:
            return "warning";
        case bloomTypes.application:
            return "success";
        case bloomTypes.analysis:
            return "error";
        case bloomTypes.evaluation:
            return "secondary";
        case bloomTypes.creation:
            return "primary";
        default:
            return "default";
    }
};

export function convertToQuestionArray(questionObj) {
    let result = [];
    const bloomTypeDefault = bloomTypes.knowledge;

    for (let [type, count] of Object.entries(questionObj)) {
        for (let i = 0; i < count; i++) {
            result.push({ type: type, bloomType: bloomTypeDefault });
        }
    }

    return result;
}
