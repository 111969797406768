import React, { createContext, useContext, useState, useEffect, useRef, useMemo } from 'react';
import api from '../requests/api';
import { useUser } from '../hooks/useUser';
import { languages } from '../utils/examOptions';

const LanguageContext = createContext();

export function useLanguage() {
  return useContext(LanguageContext)
}

export const languageCodes = {
  english: 'en',
  hebrew: 'he',
  arabic: 'ar',
}
export const languageToCodesMap = Object.keys(languages).reduce((acc, key) => {
  acc[languages[key]] = languageCodes[key.toLowerCase()];
  return acc;
}, {});

const LanguageProvider = ({ children }) => {
  const languageCode = useRef(languageCodes.english);
  const [languageData, setLanguageData] = useState({});
  const tempToken = useRef(null);
  const semiticLanguages = [languageCodes.hebrew, languageCodes.arabic];
  const isRTL = languageCode && semiticLanguages.includes(languageCode.current);
  const sessionKey = 'languageData';
  const isHeb = useMemo(() => languageCode.current === languageCodes.hebrew, [languageCode.current]);

  useEffect(() => {
    const storedPreferredLanguage = getPreferredLanguage();
    const hasSessionData = !!sessionStorage.getItem(sessionKey);

    if (storedPreferredLanguage) {
      languageCode.current = storedPreferredLanguage;
    }

    if (storedPreferredLanguage || !hasSessionData) {
      preFetchLanguageData(tempToken.current);
    } else {
      useExistingLanguageData();
    }
  }, []);

  function ToggleLanguage(newLangCode = null, token = null) {
    if (token) setToken(token)
    if (newLangCode && Object.values(languageCodes).includes(newLangCode) && newLangCode !== languageCode.current) {
      languageCode.current = newLangCode;
      // Update the URL query parameter
      // const urlParams = new URLSearchParams(window.location.search);
      // urlParams.set('lang', newLangCode);
      // const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      // window.history.pushState({}, '', newUrl);
      // fetch the language
      preFetchLanguageData(token);
    }
  }
  function getPreferredLanguage() {
    return localStorage.getItem('preferredLanguage');
  }

  async function preFetchLanguageData(token = null) {
    const storedLanguageData = sessionStorage.getItem(sessionKey);
    if (storedLanguageData) {
      const parsedData = JSON.parse(storedLanguageData);
      const parsedLanguageData = parsedData[languageCode.current];
      if (parsedLanguageData) {
        setLanguageData(parsedLanguageData);
        return parsedLanguageData;
      }
    }
    const responseLanguageData = await fetchLanguageData(token);
    if (responseLanguageData) {
      // Store languageData in sessionStorage
      const newLanguageDataToStore = { [languageCode.current]: responseLanguageData };
      const languageToStore = storedLanguageData ? { ...JSON.parse(storedLanguageData), ...newLanguageDataToStore } : newLanguageDataToStore;
      sessionStorage.setItem(sessionKey, JSON.stringify(languageToStore));
      return languageToStore
    }
  }

  async function fetchLanguageData(token = null) {
    try {
      const dataUrl = `/languages?code=${languageCode.current}`;
      const headers = {}
      if (token) headers['X-Token'] = token;
      else if (tempToken.current) headers['X-Token'] = tempToken.current;
      const response = await api.get(dataUrl, { headers })
      setLanguageData(response.data);
      return response.data;
    } catch (err) {
      console.error(err)
      return null
    }
  };

  function useExistingLanguageData() {
    const storedLanguageData = sessionStorage.getItem(sessionKey);
    if (storedLanguageData) {
      const parsedData = JSON.parse(storedLanguageData);
      const firstLanguageKey = Object.keys(parsedData)[0];
      const firstLanguageData = parsedData[firstLanguageKey];
      if (firstLanguageData) {
        setLanguageData(firstLanguageData);
        return firstLanguageData;
      }
    }
    return null;
  }

  async function fetchLanguageWithToken(token) {
    setToken(token)
    return preFetchLanguageData(token)
  }

  function changeToHebrew() {
    if (languageCode.current !== languageCodes.hebrew)
      ToggleLanguage(languageCodes.hebrew);
  }

  function changeToArabic() {
    if (languageCode.current !== languageCodes.arabic)
      ToggleLanguage(languageCodes.arabic);
  }

  const setToken = (token) => {
    tempToken.current = token
  }

  function savePreferredLanguage(lang) {
    localStorage.setItem('preferredLanguage', lang);
  }

  return (
    <LanguageContext.Provider value={{
      language: languageCode.current,
      ToggleLanguage,
      languageData,
      isRTL,
      changeToHebrew,
      languageCodes,
      changeToArabic,
      fetchLanguageWithToken,
      savePreferredLanguage,
      getPreferredLanguage,
      isHeb
    }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
