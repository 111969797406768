import React, { useEffect } from "react";

const styles = `
    body {
        margin: 0;
    }

    #accessibility-toolbar {
        font-family: arial;
        position: fixed;
        bottom: 10vh;
        right: 0;
        width: fit-content;
        margin: auto 0 auto auto;
        transform: translateY(0%);
        z-index: 1000;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        background-color: transparent;
        padding: 10px 0px 10px 10px;
    }

    #toolbar-button {
        background-color: #242e36;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 25px;
        height: 25px;
    }

    #accessibility-icon {
        margin-right: 5px;
        width: 20px;
        height: 20px;
    }

    #accessibility-menu {
        display: none;
        width: fit-content;
    }
    #accessibility-menu-content {
        width: fit-content;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    #close-button {
        background-color: #fffd;
        border: transparent;
        border-radius: 10px;
        color: #333; /* Set color as desired */
        font-size: 8px; /* Set font size as desired */
        cursor: pointer;
        align-self: flex-start; /* Align to the left within the toolbar */
        font-family: arial;
        width: 15px;
        height: 15px;
        text-align: center;
        padding: 0;
        margin: 0;
        position: fixed;
        top: 2px;
        left: 2px;
    }
`
function AccessiblityToolbar() {
    useEffect(() => {
        // Handle Style
        const styleTag = document.createElement('style');
        styleTag.innerHTML = styles;
        document.head.appendChild(styleTag);

        return () => {
            document.head.removeChild(styleTag);
        };

    }, [])

    useEffect(() => {
        // Handle JS
        const toolbarButton = document.getElementById('toolbar-button');
        const accessibilityMenu = document.getElementById('accessibility-menu');
        const hideToolbarButton = document.getElementById('close-button'); // X button
        const accessibilityToolbar = document.getElementById('accessibility-toolbar');
        const allTextElements = 'body, h1,h2,h3,h4,h5,h6,span,b,strong, p,em,i,u,s,abbr,sub,sup,mark,ins,del,a, button'
        const wheelChairSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" width="15" height="15" viewBox="0 0 483.2226563 551.4306641" overflow="visible" enable-background="new 0 0 483.2226563 551.4306641" xml:space="preserve">
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#fff" d="M161.9882813,98.1240234  c24.9628906-2.3046875,44.3574219-23.8110352,44.3574219-48.9658203C206.3457031,22.0830078,184.2626953,0,157.1875,0  s-49.1572266,22.0830078-49.1572266,49.1582031c0,8.2568359,2.3037109,16.7055664,6.1445313,23.8105469l17.515625,246.4667969  l180.3964844,0.0488281l73.9912109,173.3652344l97.1445313-38.0976563l-15.0429688-35.8203125l-54.3662109,19.625  l-71.5908203-165.2802734l-167.7294922,1.1269531l-2.3027344-31.2128906l121.4228516,0.0483398v-46.1831055l-126.0546875-0.0493164  L161.9882813,98.1240234z" />
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#fff" d="M343.4199219,451.5908203  c-30.4472656,60.1875-94.1748047,99.8398438-162.1503906,99.8398438C81.4296875,551.4306641,0,470.0009766,0,370.1611328  c0-70.1005859,42.4853516-135.2436523,105.8818359-164.1210938l4.1025391,53.5375977  c-37.4970703,23.628418-60.6123047,66.262207-60.6123047,110.9506836c0,72.4267578,59.0712891,131.4970703,131.4970703,131.4970703  c66.2617188,0,122.7646484-50.8515625,130.4697266-116.0869141L343.4199219,451.5908203z" />
            </svg>`

        toolbarButton.innerHTML = wheelChairSvg // first rendering
        toolbarButton.addEventListener('click', toggleDisplayToolbar);
        document.getElementById('font-size').addEventListener('change', handleChangeFontSize);
        document.getElementById('line-spacing').addEventListener('change', handleChangeLineSpace);
        document.getElementById('stop-animations').addEventListener('change', handleStopAnimation);
        document.getElementById('keyboard-navigation').addEventListener('change', handleToggleKeyboardNavigation);
        document.getElementById('grayscale').addEventListener('change', handleToggleGrayscale);
        document.getElementById('negative-contrast').addEventListener('change', handleToggleNegativeContrast);
        document.getElementById('high-contrast').addEventListener('change', handleToggleHighContrast);
        document.getElementById('light-background').addEventListener('change', function () { /* document.body.style.backgroundColor = this.checked ? '#fff' : '#f0f0f0';*/ });
        document.getElementById('underline-links').addEventListener('change', handleToggleUnderlineLinks);
        document.getElementById('readable-font').addEventListener('change', HandleToggleReadbleFont);
        document.getElementById('close-button').addEventListener('click', hideToolbar)

        function toggleDisplayToolbar(e) {
            if (accessibilityMenu.style.display === 'block') {
                // hide toolbar
                accessibilityMenu.style.display = 'none';
                hideToolbarButton.style.display = 'block';
                toolbarButton.innerHTML = wheelChairSvg
                accessibilityToolbar.style.backgroundColor = 'transparent'
                accessibilityToolbar.style.border = '0px solid'
            } else {
                // display toolbar
                accessibilityMenu.style.display = 'block';
                hideToolbarButton.style.display = 'none';
                toolbarButton.innerHTML = 'X'
                accessibilityToolbar.style.backgroundColor = '#fffd'
                accessibilityToolbar.style.border = '1px solid'
            }
        }
        function handleChangeFontSize(e) {
            const percentageMultiplier = parseInt(this.value);
            const elementsToAdjust = document.querySelectorAll(allTextElements);

            elementsToAdjust.forEach(element => {
                if (!element.originalFontSize) {
                    // Store the original font size if not already stored
                    element.originalFontSize = window.getComputedStyle(element).fontSize;
                }
                const currentFontSize = parseFloat(element.originalFontSize);

                if (!isNaN(currentFontSize)) {
                    const newFontSize = currentFontSize * (percentageMultiplier / 100);
                    element.style.fontSize = newFontSize + 'px';
                }
            });
        }
        function handleChangeLineSpace() {
            document.body.style.lineHeight = this.value;
        }
        function handleStopAnimation() {
            document.body.style.animationPlayState = this.checked ? 'paused' : 'running';
        }
        function handleToggleKeyboardNavigation() {
            if (this.checked) {
                document.body.addEventListener('keydown', handleKeyboardNavigation);
            } else {
                document.body.removeEventListener('keydown', handleKeyboardNavigation);
            }
        }
        const handleKeyboardNavigation = (event) => {
            const links = document.querySelectorAll('a');
            let focusedIndex = Array.from(links).findIndex((link) => link === document.activeElement);

            switch (event.key) {
                case 'ArrowUp':
                    focusedIndex = (focusedIndex - 1 + links.length) % links.length;
                    break;
                case 'ArrowDown':
                    focusedIndex = (focusedIndex + 1) % links.length;
                    break;
                default:
                    break;
            }

            // Focus on the new link
            links[focusedIndex].focus();
        };
        function handleToggleGrayscale() {
            document.body.style.filter = this.checked ? 'grayscale(100%)' : 'none';
        }
        function handleToggleNegativeContrast() {
            document.body.style.filter = this.checked ? 'invert(100%)' : 'none';
        }
        function handleToggleHighContrast() {
            document.body.style.filter = this.checked ? 'contrast(200%)' : 'none';
        }
        function handleToggleUnderlineLinks() {
            const links = document.querySelectorAll('a');
            links.forEach(link => {
                link.style.textDecoration = this.checked ? 'underline' : 'none';
            });
        }
        function HandleToggleReadbleFont() {
            const elementsToAdjust = document.querySelectorAll(allTextElements);
            elementsToAdjust.forEach(element => {
                element.style.fontFamily = this.checked ? 'Arial, sans-serif' : '';
            });
        }
        function hideToolbar() {
            accessibilityToolbar.style.display = 'none';
        }

        return () => {
            toolbarButton.removeEventListener('click', toggleDisplayToolbar);
            document.getElementById('font-size').removeEventListener('change', handleChangeFontSize);
            document.getElementById('line-spacing').removeEventListener('change', handleChangeLineSpace);
            document.getElementById('stop-animations').removeEventListener('change', handleStopAnimation);
            document.getElementById('keyboard-navigation').removeEventListener('change', handleToggleKeyboardNavigation);
            document.getElementById('grayscale').removeEventListener('change', handleToggleGrayscale);
            document.getElementById('negative-contrast').removeEventListener('change', handleToggleNegativeContrast);
            document.getElementById('high-contrast').removeEventListener('change', handleToggleHighContrast);
            // document.getElementById('light-background').removeEventListener('change', function () { /* document.body.style.backgroundColor = this.checked ? '#fff' : '#f0f0f0';*/ });
            document.getElementById('underline-links').removeEventListener('change', handleToggleUnderlineLinks);
            document.getElementById('readable-font').removeEventListener('change', HandleToggleReadbleFont);
            document.getElementById('close-button').removeEventListener('click', hideToolbar)
        }
    })

    return (
        <>
            <div id="accessibility-toolbar">
                <div>
                    <button id="close-button">X</button>

                    <button id="toolbar-button">
                    </button>
                </div>
                <div id="accessibility-menu">
                    <div id="accessibility-menu-content">
                        <div>
                            <label htmlFor="font-size">Font Size:</label>
                            <select id="font-size">
                                <option value="100">Default</option>
                                <option value="75">75%</option>
                                <option value="100">100%</option>
                                <option value="125">125%</option>
                                <option value="150">150%</option>
                                <option value="175">175%</option>
                                <option value="200">200%</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="line-spacing">Line Spacing:</label>
                            <select id="line-spacing">  
                                <option value="normal">Normal</option>
                                <option value="1.5">1.5x</option>
                                <option value="2">2x</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="stop-animations">Stop Animations:</label>
                            <input type="checkbox" id="stop-animations" />
                        </div>

                        <div>
                            <label htmlFor="keyboard-navigation">Keyboard Navigation:</label>
                            <input type="checkbox" id="keyboard-navigation" />
                        </div>

                        <div>
                            <label htmlFor="grayscale">Grayscale:</label>
                            <input type="checkbox" id="grayscale" />
                        </div>

                        <div>
                            <label htmlFor="negative-contrast">Negative Contrast:</label>
                            <input type="checkbox" id="negative-contrast" />
                        </div>

                        <div>
                            <label htmlFor="high-contrast">High Contrast:</label>
                            <input type="checkbox" id="high-contrast" />
                        </div>

                        <div>
                            <label htmlFor="light-background">Light Background:</label>
                            <input type="checkbox" id="light-background" />
                        </div>

                        <div>
                            <label htmlFor="underline-links">Links Underline:</label>
                            <input type="checkbox" id="underline-links" />
                        </div>

                        <div>
                            <label htmlFor="readable-font">Readable Font:</label>
                            <input type="checkbox" id="readable-font" />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AccessiblityToolbar