import React, { useState, useMemo, useEffect } from 'react';
import { Box, Button, Typography, Card, Grid, CardContent, LinearProgress } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';
import { useNavigate } from 'react-router-dom';
import { defaultParams, questionsType } from '../../utils/examOptions';
import AvatarList from './AvatarList';  // Import AvatarList component
import CustomLatex, { latexDelimiter } from '../../components/CustomLatex';
import { inputTypes } from '../../utils/configs';
import { renderTextWithBreakLines } from '../../utils';

const FrequentlyMistakenQuestions = ({ students, examJson }) => {
    const [topMistakenQuestions, setTopMistakenQuestions] = useState([]);
    const [viewMore, setViewMore] = useState(false);
    const { languageData, isRTL } = useLanguage();
    const navigate = useNavigate();

    const handleRemix = (mistake) => {
        const { focus, difficulty, language, source, skills } = examJson.parameters;
        let { questions } = examJson?.parameters
        if (!questions || questions.length === 0) {
            questions = examJson?.questions.map(q => ({
                type: q?.type,
                bloomType: q?.bloomType
            })) || defaultParams.questions;
        }

        const inputSource = source === inputTypes.url || source === inputTypes.math ? source : inputTypes.text;

        const filteredMistakes = source === inputTypes.math ? mistake.replaceAll(latexDelimiter, '') : mistake;
        const promptMessage = language === 'Hebrew'
            ? `המבחן הבא יתמקד בשאלות שהסטודנטים טעו בהן במבחן הקודם: "${examJson.title}". בנוסף, כלול שאלות ברמת קושי נמוכה יותר או בשכתוב, כדי לאפשר לתלמידים להתאמן על הנושאים בהם התקשו. טעויות: ${filteredMistakes}`
            : `The next exam will focus on the questions that students most frequently missed in the previous exam titled: "${examJson.title}". Additionally, include lower-level or reworded questions to give students more practice on topics they struggled with. Mistakes: ${filteredMistakes}`;

        const remixData = { prompt: promptMessage, focus, questions, difficulty, language, skills, source };
        sessionStorage.setItem('remix', JSON.stringify(remixData));
        navigate(`/exam?input=${inputSource}`);
    };

    const topMistakenQuestionsMemoized = useMemo(() => {
        if (!students || !examJson?.questions?.length) return [];
        const mistakenCount = {};

        students.forEach(({ answers }) => {
            answers.forEach(({ question_id, answer, grade }) => {
                const question = examJson.questions.find(q => q._id === question_id);
                if (!question) return;

                const isIncorrectAnswer =
                    (question.type === questionsType.multiple || question.type === questionsType.fill_in) &&
                    !question.correctAnswers.includes(answer);
                const isLowGrade =
                    (question.type === questionsType.open || question.type === questionsType.graph) &&
                    grade < question.grade * 0.5;

                if (isIncorrectAnswer || isLowGrade) {
                    mistakenCount[question_id] = (mistakenCount[question_id] || 0) + 1;
                }
            });
        });

        return Object.entries(mistakenCount)
            .sort(([, a], [, b]) => b - a)
            .slice(0, 10)
            .map(([id, mistakes]) => ({
                question: examJson.questions.find(q => q._id === id),
                mistakes,
            }));
    }, [students, examJson]);

    useEffect(() => setTopMistakenQuestions(topMistakenQuestionsMemoized), [topMistakenQuestionsMemoized]);

    if (!topMistakenQuestions.length) return null;

    const displayedQuestions = viewMore ? topMistakenQuestions : topMistakenQuestions.slice(0, 3);
    const top3MistakesConcat = displayedQuestions.map(q => q.question.question).join(' ');

    return (
        <>
            <Grid container spacing={2} p={2}>
                {displayedQuestions.map((mistakenQuestion, index) => {
                    const mistakePercentage = ((mistakenQuestion.mistakes / students.length) * 100).toFixed(0);

                    return (
                        <Grid item xs={12} key={index}>
                            <Card elevation={3} sx={{ borderRadius: 2 }}>
                                <CardContent>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        textAlign: isRTL ? 'right' : 'left',
                                        mb: 2
                                    }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                            {languageData?.review?.analytics?.frequenlty_mistaken} {' '} {index + 1}
                                        </Typography>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-end',
                                            gap: 0.5,
                                            width: '30%',
                                        }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                                                    {mistakenQuestion.mistakes}/{students.length}
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'error.main' }}>
                                                    {mistakePercentage}%
                                                </Typography>
                                            </Box>

                                            <LinearProgress
                                                variant="determinate"
                                                value={parseFloat(mistakePercentage)}
                                                color="error"
                                                sx={{
                                                    height: 6,
                                                    borderRadius: 5,
                                                    '& .MuiLinearProgress-bar': {
                                                        borderRadius: 5,
                                                    },
                                                    width: '100%',
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box sx={{ textAlign: isRTL ? 'right' : 'left', mb: 2 }}>
                                        <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                            {renderTextWithBreakLines(mistakenQuestion.question.question)}
                                        </Typography>
                                    </Box>

                                    <AvatarList students={students} mistakenQuestion={mistakenQuestion} />
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}

                {topMistakenQuestions.length && (
                    <Grid item xs={12} mt={2}>
                        <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
                            {topMistakenQuestions.length > 3 && (
                                <Grid item xs={3}>
                                    <Button variant="text" color="primary" onClick={() => setViewMore(!viewMore)}>
                                        {viewMore ? languageData?.review?.analytics?.view_less : languageData?.review?.analytics?.view_more}
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs={topMistakenQuestions.length > 3 ? 9 : 12}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => handleRemix(top3MistakesConcat)}
                                    sx={{
                                        color: 'black',
                                        borderColor: 'black',
                                        '&:hover': { backgroundColor: '#f5f5f5' }
                                    }}
                                >
                                    {languageData?.review?.analytics?.learn_from_mistakes || 'Learn from mistakes'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default FrequentlyMistakenQuestions;
