import { Button, Popover, Dialog, DialogTitle, DialogActions, Typography } from "@mui/material";
import { useLanguage } from "../contexts/languageContext";

export function DeleteConfirmationPopver({
    type = "popover", // "popover" or "dialog"
    anchorEl,
    open = Boolean(anchorEl),
    onClose,
    anchorOrigin = 'bottom',
    slotProps = { paper: { sx: { p: 1, pt: 0 } } },
    onCancel,
    onDelete
}) {
    const { languageData } = useLanguage();

    if (type === "dialog") {
        // ? Use dialog if the delete confirmation is crucial and caution is needed
        return (
            <Dialog open={open} onClose={onClose} PaperProps={{ sx: { minWidth: '20rem', textAlign: 'center' } }}>
                <DialogTitle>
                    {languageData?.delete_confirmation?.title ?? 'Confirm to Delete'}
                </DialogTitle>
                <DialogActions sx={{ justifyContent: 'space-between' }}>
                    <Button onClick={onCancel}>
                        {languageData?.delete_confirmation?.cancel ?? 'Cancel'}
                    </Button>
                    <Button color="error" variant="contained" onClick={onDelete}>
                        {languageData?.delete_confirmation?.delete ?? 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Popover
            id="delete-popover"
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={anchorOrigin}
            slotProps={slotProps}
        >
            <Typography sx={{ p: 2, pb: 0 }}>
                {languageData?.delete_confirmation?.title ?? 'Confirm to Delete'}
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: '16px 0' }}>
                <Button variant="outlined" onClick={onCancel}>
                    {languageData?.delete_confirmation?.cancel ?? 'Cancel'}
                </Button>
                <Button variant="contained" color="error" onClick={onDelete}>
                    {languageData?.delete_confirmation?.delete ?? 'Delete'}
                </Button>
            </div>
        </Popover>
    );
}
