import React, { useState, useEffect, useMemo } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Box,
} from "@mui/material";
import { languageCodes, useLanguage } from "../contexts/languageContext";

const defaultSteps = [
    {
        title: "Create Exam",
        subtitle: "Craft exams effortlessly using various input types to suit your needs. Add questions and customize them seamlessly.",
        image: "https://res.cloudinary.com/studywise/image/upload/v1733321715/%D7%99%D7%A6%D7%99%D7%A8%D7%AA_%D7%9E%D7%91%D7%97%D7%9F_wwll18.png",
    },
    {
        title: "Preview Page",
        subtitle: "Review and refine questions and answers before sharing. Make final edits and ensure everything is perfect. Ready? Share it directly with students.",
        image: "https://res.cloudinary.com/studywise/image/upload/v1733321715/Preview_m1wv2h.png",
    },
    {
        title: "Assign Exam to LMS Resource",
        subtitle: "Easily link your exam to an LMS resource to provide seamless access for students. Without this step, the exam remains inaccessible to students.",
        image: "https://res.cloudinary.com/studywise/image/upload/v1733320946/%D7%A6%D7%99%D7%9C%D7%95%D7%9D_%D7%9E%D7%A1%D7%9A_2024-12-04_160201_zdzlco.png",
    },
    {
        title: "Auto Grade",
        subtitle: "Once students complete the exam, our auto-grading system kicks in to evaluate their responses instantly.",
        image: "https://res.cloudinary.com/studywise/image/upload/v1736840147/auto-grade_akqvbx.gif",
    },
    {
        title: "Dashboard",
        subtitle: "Monitor live updates of class and student performance. Get a real-time snapshot of progress and results at a glance.",
        image: "https://res.cloudinary.com/studywise/image/upload/v1733321714/Dashboard_e1va5w.png",
    },
    {
        title: "Sync Grades Back to LMS",
        subtitle: "Finalize and sync grades directly back to the LMS for students to review. Note: This action is irreversible, so review carefully before proceeding.",
        // image: "https://res.cloudinary.com/studywise/image/upload/c_crop,w_630,h_320/v1734444619/Grades_sync_vpsau4.png",
        image: "https://res.cloudinary.com/studywise/image/upload/v1734444619/Grades_sync_vpsau4.png",
    },
];

const LMStutorialStepper = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const { language, isRTL, languageData } = useLanguage();
    // Check localStorage to decide whether to show the dialog
    useEffect(() => {
        const LMSTutorialFinished = localStorage.getItem("LMSTutorialFinished");
        if (!LMSTutorialFinished) setDialogOpen(true);
    }, []);

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            localStorage.setItem("LMSTutorialFinished", true);
            setDialogOpen(false);
        } else {
            setActiveStep((prevStep) => prevStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    const steps = languageData?.lms_tutorial?.steps ?? defaultSteps

    return (
        <Dialog
            open={isDialogOpen}
            onClose={(e) => e.stopPropagation()} // Prevent closing on outside click
            fullWidth
            maxWidth="md"
            slotProps={{ backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, 0.7)' } } }}
        >
            {/* Stepper at the top */}
            <Box sx={{ padding: 2 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel>{ }</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            <DialogTitle sx={{ textAlign: "center", pb: 0 }}>
                {languageData?.lms_tutorial?.topic}
            </DialogTitle>

            {/* Title and subtitle */}
            <DialogTitle sx={{ textAlign: isRTL ? "right" : "left", direction: isRTL ? "rtl" : "ltr" }}>
                {steps[activeStep].title}
                <Typography variant="subtitle1" color="textSecondary">
                    {steps[activeStep].subtitle}
                </Typography>
            </DialogTitle>

            {/* Content with image */}
            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                    sx={{ overflow: "hidden", height: '370px' }}
                >
                    {steps[activeStep].image && (
                        <img
                            src={steps[activeStep].image}
                            alt={steps[activeStep].title}
                            style={{
                                width: "100%",
                                maxHeight: "100%", // Prevents image overflow
                                objectFit: "contain",
                            }}
                        />
                    )}
                </Box>
            </DialogContent>


            {/* Actions with Next and Back buttons */}
            <DialogActions
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 2,
                }}
            >
                <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    {languageData?.lms_tutorial?.back}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNext}
                >
                    {/* {activeStep === steps.length - 1 ? "Finish" : "Next"} */}
                    {languageData?.lms_tutorial?.next}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LMStutorialStepper;
