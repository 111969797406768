import React, { useRef, useMemo, useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box, TextField, Paper, CircularProgress } from '@mui/material';
import { colors } from '../../utils/customTheme';
import { useLanguage } from '../../contexts/languageContext';
import { useSubjects } from '../../contexts/SubjectsContext';

const SubjectSelectionStep = ({ handleSubjectSelection, selectedSubjects }) => {
  const otherSubjectRef = useRef('');
  const { languageData, language } = useLanguage();
  const { subjects } = useSubjects();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (subjects && subjects.length) {
      setIsLoading(false);
    }
  }, [subjects])

  const sortedSubjects = useMemo(() => {
    const sorted = subjects?.sort((a, b) => {
      const labelA = a.labels[language] || a.labels.en;
      const labelB = b.labels[language] || b.labels.en;
      return labelA.localeCompare(labelB);
    });

    return sorted;
  }, [subjects, language]);

  const handleSubjectChange = (subjectId) => {
    const updatedSubjects = selectedSubjects.includes(subjectId)
      ? selectedSubjects.filter((prevSubject) => prevSubject !== subjectId)
      : [...selectedSubjects, subjectId];

    handleSubjectSelection(updatedSubjects);
  };

  const handleOtherSubjectChange = (event) => {
    otherSubjectRef.current = event.target.value;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
          {languageData?.onboarding?.subject_step?.title || 'Please choose your subjects'}
        </Typography>
      </Grid>
      {isLoading ? <CircularProgress sx={{ margin: '20px auto' }} /> :
        sortedSubjects?.map((subject) => (
          <Grid item xs={12} sm={6} md={4} key={subject._id}>
            <Paper
              elevation={0}
              sx={{
                border: selectedSubjects.includes(subject._id)
                  ? `2px solid ${colors.main}`
                  : '2px solid transparent',
                transition: 'border-color 0.3s',
                cursor: 'pointer'
              }}
            >
              <Card
                variant="outlined"
                onClick={() => handleSubjectChange(subject._id)}
              >
                <CardContent>
                  <Box display="flex" justifyContent="center">
                    <Typography variant="body1" sx={{ m: 1, fontWeight: 'bold' }}>
                      {subject.labels[language] || subject.labels.en}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        ))}
      <Grid item xs={12} sx={{ mb: 2 }}>
        <TextField
          label={languageData?.onboarding?.subject_step?.other || 'Other Subject'}
          placeholder={languageData?.onboarding?.subject_step?.type_other || 'Type other subject here'}
          onChange={handleOtherSubjectChange}
          onBlur={(event) => handleSubjectChange(event.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default SubjectSelectionStep;
