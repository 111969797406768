import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import ReportIcon from '@mui/icons-material/Report';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import GroupsIcon from '@mui/icons-material/Groups';
import { links } from '../../utils';
import { colors } from '../../utils/customTheme';

/**
 * @param {Object} languageData 
 * @param {{isStudent: boolean, isTeacher: boolean}} options 
 * @returns 
 */
export const getMenuLinks = (languageData, options) => {
    const { isStudent, isTeacher } = options;
    const menuLinks = [
        // { title: languageData?.menu?.lobby ?? "Lobby", path: '/lobby', target: '_self', icon: <DashboardCustomizeOutlinedIcon /> },
        // { title: languageData?.menu?.exam ?? "Create", path: '/exam', target: '_self', icon: <AddCircleOutlineOutlinedIcon /> },
        { title: languageData?.menu?.history ?? "My Exams", path: isStudent ? '/student/history' : '/history', target: '_self', icon: <HistoryOutlinedIcon /> },
        { title: languageData?.menu?.personal ?? "Personalization", path: '/personal', target: '_self', icon: <AutoAwesomeIcon /> },
        { title: languageData?.menu?.hub ?? "Explore", path: '/explore', target: '_self', icon: <GroupWorkOutlinedIcon /> },
        { title: languageData?.menu?.feedback ?? "Report A Bug", path: links.BUG_FORM, target: '_blank', icon: <ReportIcon color='error' /> },
        { title: languageData?.menu?.howto ?? "Tutorials", path: '/tutorials', target: '_blank', icon: <VideoLibraryOutlinedIcon color='success' /> },
    ];
    if (isTeacher) {
        menuLinks.splice(3, 0, { title: languageData?.menu?.my_students ?? "My Students", path: '/mystudents', target: '_self', icon: <GroupsIcon /> });
    }
    return menuLinks;
};
export const homepage = "/exam"
export const textpage = "/exam?input=text"
export const uploadfilepage = "/exam?input=uploads"
export const mathpage = "/exam?input=math"
export const onboarding = '/start'
export const withMenuPages = [
    '/exam',
    '/history',
    '/dashboard',
    '/dashboard/org',
    '/dashboard/subjects',
    '/dashboard/skills',
    '/review',
    '/preview',
    '/explore',
    '/pricing',
    '/blog',
    '/dashboard/emails',
    '/lobby',
    '/personal',
    '/mystudents',
]
