import api from "./api";
import { processResponsePromise } from "./utils";

class APIKeys {
    static fetchAllKeys(orgId) {
        return processResponsePromise(api.get(`/orgs/${orgId}/keys`));
    }

    /**
     * @param {string} orgId 
     * @param {{secret: string, expiration: Date}} keyData 
     */
    static createKey(orgId, keyData) {
        const { expiration } = keyData;
        keyData.expiration = expiration instanceof Date ? expiration.getTime() : null;
        return processResponsePromise(api.post(`/orgs/${orgId}/keys`, keyData));
    }

    static deactivateKey(orgId, api_key) {
        return processResponsePromise(api.post(`/orgs/${orgId}/keys/deactivate`, { api_key }));
    }
}

export default APIKeys;