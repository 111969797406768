import React from 'react';
import { LinearProgress, Box, Typography, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import { useLanguage } from '../../contexts/languageContext';
import { colors } from '../../utils/customTheme';

const ProgressBar = styled(LinearProgress)(({ theme, progressColor }) => ({
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.grey[300],
    '& .MuiLinearProgress-bar': {
        backgroundColor: progressColor || colors.success,
    },
}));

const ReviewProgressBar = ({ students }) => {
    const { languageData, isRTL } = useLanguage();
    const totalStudents = students.length;

    const reviewedStudents = students.filter(student => student.reviewSubmitted).length;
    const saveForLaterStudents = students.filter(student => student.saveForLater).length;

    const progressReview = totalStudents > 0 ? (reviewedStudents / totalStudents) * 100 : 0;
    const progressSaveForLater = totalStudents > 0 ? (saveForLaterStudents / totalStudents) * 100 : 0;

    return (
        <Card elevation={0} sx={{ direction: isRTL ? 'rtl' : 'ltr' }}>
            <CardContent>
                <Box>
                    <Typography
                        variant="body2"
                        gutterBottom
                        sx={{
                            textAlign: isRTL ? 'right' : 'left',
                            fontWeight: 'bold',  // Make text bold
                        }}
                    >
                        {languageData?.review?.response?.submit ?? "Review Progress"}{' '}
                        {reviewedStudents}/{totalStudents}
                    </Typography>
                    <ProgressBar variant="determinate" value={progressReview} />

                    <Typography
                        variant="body2"
                        gutterBottom
                        sx={{
                            textAlign: isRTL ? 'right' : 'left',
                            marginTop: 2,
                            fontWeight: 'bold',  // Make text bold
                        }}
                    >
                        {languageData?.review?.response?.save ?? "Save For Later"}{' '}
                        {saveForLaterStudents}/{totalStudents}
                    </Typography>
                    <ProgressBar variant="determinate" value={progressSaveForLater} progressColor={colors.warning} />
                </Box>
            </CardContent>
        </Card>
    );
};

export default ReviewProgressBar;
