import React from 'react'
import { Button } from '@mui/material'
import { loginMoeIL } from '../requests/auth'
import EduLogo from '../assets/logo-edu.png';
import { colors } from '../utils/customTheme';

/**
 * 
 * @param {string} param0.redirectUrl - the redurect url after auth
 * @param {string} param0.buttonLabel - the button label
 * @param {Object} param0.buttonProps - additional button props
 * @param {Function} param0.preClick - function to run before onClick
 * @returns 
 */
export function IdmMoeILButton({ redirectUrl, buttonLabel = "הזדהות משרד החינוך", buttonProps = {}, preClick = null }) {
    const handleLogin = async () => {
        if (preClick) preClick()
        if (redirectUrl)
            localStorage.setItem('redirectUrl', redirectUrl)

        return loginMoeIL().then(data => {
            if (data?.redirect_url) {
                window.open(data.redirect_url, '_self')
            }
        })
    }
    return (
        <Button
            variant='outlined'
            onClick={handleLogin}
            startIcon={<img src={EduLogo} alt="Edu Logo" style={{ width: 24, height: 24 }} />}
            sx={{ width: '-webkit-fill-available', backgroundColor: 'white', color: colors.main }}
            {...buttonProps}
        >
            {buttonLabel}
        </Button>
    )
}