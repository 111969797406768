import { HttpStatusCode } from "axios"
import api from "./api"
import { processResponsePromise } from "./utils"

export async function getAllOrgs() {
    return api.get('/orgs').then(res => {
        if (res.status === HttpStatusCode.Ok)
            return res.data
        else
            // Didnt find org
            return null
    }).catch(e => {
        // Something went wrong, didnt find org
        return null
    })
}

export async function getOrgProfile(id, token = null) {
    return api.get(`/orgs/${id}`, { headers: { "X-Token": token } }).then(res => {
        if (res.status === HttpStatusCode.Ok)
            return res.data
        else
            // Didnt find org
            return null
    }).catch(e => {
        // Something went wrong, didnt find org
        return null
    })
}

export async function postOrgSignup(payload) {
    return api.post(`/auth/signup/org`, payload).then(response => {
        if (response.status === HttpStatusCode.Ok) {
            return response.data
        } else {
            console.error(response)
        }
    })
}

export async function createOrg(org, openSnackBar) {
    return api.post('/orgs', { org }).then(response => {
        if (response.status === HttpStatusCode.Ok) {
            openSnackBar('ORG_CREATED_SUCCESS')
            return response.data
        }
    }).catch(e => {
        console.log(e)
        openSnackBar('ORG_CREATED_FAILED')
    })
}

export async function editOrg(org_id, org, openSnackBar) {
    return api.put(`/orgs/${org_id}`, { org }).then(response => {
        if (response.status === HttpStatusCode.Ok) {
            openSnackBar('ORG_UPDATED_SUCCESS')
            return response.data
        }
    }).catch(e => {
        console.log(e)
        openSnackBar('ORG_UPDATED_FAILED')
    })
}

export function getOrgUsers(orgId) {
    return processResponsePromise(api.get(`/orgs/${orgId}/users`))
}

export function getOrgExamsTakenData(orgId) {
    return processResponsePromise(api.get(`/orgs/${orgId}/dashboard/exams`))
}

export function getOrgResponsesCount(orgId) {
    return processResponsePromise(api.get(`/orgs/${orgId}/dashboard/responses`))
}

export function getOrgTokensCount(orgId) {
    return processResponsePromise(api.get(`/orgs/${orgId}/dashboard/tokens`))
}

export function getOrgExamsSources(orgId) {
    return processResponsePromise(api.get(`/orgs/${orgId}/dashboard/sources`))
}

export function getExamResponseSummary(orgId) {
    return processResponsePromise(api.get(`/orgs/${orgId}/dashboard/summary`));
}
