// React
import { useState, useRef, useMemo } from "react";
import PropTypes from 'prop-types';
// MUI
import { Toolbar, Table, TableRow, TablePagination, TableCell, TableBody, TableContainer, Paper, Typography, Button, CircularProgress } from "@mui/material";
// Components
import EnhancedTableHead from "./EnhancedTableHead";
import TablePaginationActions from "./TablePaginationActions";
// utils
import { isMobile, getComparator, orders, capitalizeFirstLetter, renderTextWithBreakLines } from "../../utils";

// Props types at the end of the file
function DashboardTable({ items, tableName, fieldsToDisplay, orderByField, sortableFields, CustomPopover, popoverProps, allowPopover, refetchData = null }) {
    const rowsPerPageList = [500, 1000, 5000, { label: 'All', value: -1 }]
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(500);
    const [order, setOrder] = useState(orders.descending);
    const [orderBy, setOrderBy] = useState(orderByField);
    const headerRef = useRef(null)
    const focusedItemRef = useRef(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false)

    if (!fieldsToDisplay && items && items.length)
        fieldsToDisplay = Object.keys(items[0])

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = useMemo(
        () => page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0,
        [page, rowsPerPage, items]
    )
    const sortedItems = useMemo(
        () => // Re-sorting the items
            orderBy && items ? items.sort(getComparator(order, orderBy)) : items,
        [orderBy, order, items]
    )
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === orders.ascending;
        setOrder(isAsc ? orders.descending : orders.ascending);
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        headerRef.current?.scrollIntoView() // Scroll to top
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // popover function
    const handleClosePopOver = () => {
        focusedItemRef.current = null
        setAnchorEl(null);
    };

    const onClickRow = (e, item) => {
        focusedItemRef.current = { ...item }
        setAnchorEl(e.currentTarget);
    }

    const handleRefetch = () => {
        if (!refetchData) return
        setLoading(true)
        refetchData().finally(() => setLoading(false))
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Toolbar>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {capitalizeFirstLetter(tableName)} ({sortedItems.length})
                </Typography>
                <Button onClick={handleRefetch} variant="contained" color="primary" disabled={!refetchData || loading}>
                    {loading ? <CircularProgress size={24} /> : "Refetch"}
                </Button>
            </Toolbar>
            <TableContainer sx={{ maxHeight: isMobile ? '60vh' : '70vh' }}>
                <Table stickyHeader sx={{ width: '100%' }} size={'small'}>
                    <EnhancedTableHead ref={headerRef} headCells={fieldsToDisplay} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} sortableFields={sortableFields} />
                    <TableBody>
                        {sortedItems && (rowsPerPage > 0
                            ? sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : sortedItems).map((item, i) =>
                                <TableRow
                                    key={i}
                                    onClick={(event) => allowPopover ? onClickRow(event, item) : null}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        backgroundColor: fieldsToDisplay.includes('environment') && item.environment === 'development' ? 'rgba(255, 255, 0, 0.2)' : 'inherit'
                                    }}
                                >
                                    {fieldsToDisplay.map((field, i) =>
                                        <TableCell key={i} sx={{ padding: '2px 5px', textAlign: 'center', minWidth: field === 'roles' ? '200px' : '120px' }}>{
                                            field === 'roles' ?
                                                (item?.roles?.join(', ') || item.role) :
                                                renderTextWithBreakLines(item[field]?.toString())}
                                        </TableCell>
                                    )}
                                </TableRow>
                            )}
                        {emptyRows > 0 && (
                            <TableRow sx={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}

                        {/* PopOver for edit */}
                        {focusedItemRef.current &&
                            <CustomPopover
                                anchorEl={anchorEl}
                                handleClosePopOver={handleClosePopOver}
                                item={focusedItemRef.current}
                                {...popoverProps}
                            />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageList}
                colSpan={1}
                count={sortedItems.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: {
                        'aria-label': 'rows per page',
                    },
                    native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </Paper>
    )
}

export default DashboardTable;


DashboardTable.propTypes = {
    items: PropTypes.array,
    tableName: PropTypes.string,
    fieldsToDisplay: PropTypes.array,
    orderByField: PropTypes.string,
    sortableFields: PropTypes.array,
    CustomPopover: PropTypes.element,
    SubmitPopover: PropTypes.func,
    allowPopover: PropTypes.bool, // allow on specific tables
}