import React, { useMemo } from 'react';
import { Typography, Card, CardContent, Grid } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';

const ExamAnalytics = ({ students }) => {
    const { languageData } = useLanguage()

    // Calculate average grade
    const calculateAverage = useMemo(() => {
        const totalGrades = students.reduce((acc, student) => acc + student.grade, 0);
        return students.length > 0 ? (totalGrades / students.length).toFixed(0) : 0;
    }, [students]);

    // Calculate median grade
    const calculateMedian = useMemo(() => {
        const sortedGrades = students.map(student => student.grade).sort((a, b) => a - b);
        const middleIndex = Math.floor(sortedGrades.length / 2);
        if (sortedGrades.length % 2 === 0) {
            return ((sortedGrades[middleIndex - 1] + sortedGrades[middleIndex]) / 2).toFixed(0);
        } else {
            return sortedGrades[middleIndex].toFixed(0);
        }
    }, [students]);

    // Calculate range of grades
    const calculateRange = useMemo(() => {
        const sortedGrades = students.map(student => student.grade).sort((a, b) => a - b);
        const minGrade = sortedGrades.length > 0 ? sortedGrades[0] : 0;
        const maxGrade = sortedGrades.length > 0 ? sortedGrades[sortedGrades.length - 1] : 0;
        return [minGrade, maxGrade];
    }, [students]);

    return (
        <Grid container spacing={1} sx={{ textAlign: 'center' }}>
            <Grid item xs={12} sm={4}>
                <Card elevation={0} >
                    <CardContent>
                        <Typography variant="subtitle1" sx={{ fontWeight: '700' }} gutterBottom>{languageData?.review?.analytics?.average}</Typography>
                        <Typography variant="h5" sx={{ direction: "ltr" }} >{calculateAverage} / 100</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card elevation={0} >
                    <CardContent>
                        <Typography variant="subtitle1" sx={{ fontWeight: '700' }} gutterBottom>{languageData?.review?.analytics?.median}</Typography>
                        <Typography variant="h5" sx={{ direction: "ltr" }}>{calculateMedian} / 100</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card elevation={0} >
                    <CardContent>
                        <Typography variant="subtitle1" sx={{ fontWeight: '700' }} gutterBottom>{languageData?.review?.analytics?.range}</Typography>
                        <Typography variant="h5" sx={{ direction: "ltr" }}>{calculateRange[0]} - {calculateRange[1]}</Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ExamAnalytics;
