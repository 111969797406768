import { forwardRef, useState } from 'react';
import { Button } from '@mui/material';
import MergeIcon from '@mui/icons-material/Merge';
import MergeDialog from './MergeDialog';
import { useLanguage } from '../../contexts/languageContext';
import { iconButtonStyle } from '../../utils/customTheme';
import { isMobile } from '../../utils';

const MergeButton = forwardRef(({ title, isRTL: propIsRTL, languageData: previewLanguageData, onMerge, examId, ...props }, ref) => {
    const { isRTL: contextIsRTL } = useLanguage();
    const isRTL = propIsRTL ?? contextIsRTL;
    const iconBtnStyle = iconButtonStyle.mobile
    const [openMergeDialog, setOpenMergeDialog] = useState(false);

    const handleMergeClick = () => {
        setOpenMergeDialog(true);
    };

    const handleCloseMergeDialog = () => {
        setOpenMergeDialog(false);
    };

    return (
        <>
            <Button
                {...props}
                ref={ref}
                title={title}
                variant='contained'
                color='primary'
                startIcon={<MergeIcon />}
                sx={iconBtnStyle}
                onClick={handleMergeClick}
            >
            </Button>
            <MergeDialog
                isRTL={isRTL}
                languageData={previewLanguageData?.merge_dialog}
                open={openMergeDialog}
                onClose={handleCloseMergeDialog}
                onMerge={onMerge}
                currentExamId={examId}
            />
        </>
    );
});

export default MergeButton;
